'use client';

import { Box, createListCollection, Select, SelectRootProps } from '@chakra-ui/react';
import { Controller, FieldValues } from 'react-hook-form';
import { FormInput, FormInputPropsWithoutChildren } from '.';
import { SelectClearTrigger } from '../ui/select';

export const SingleSelectInput = <TFieldValues extends FieldValues>({
  form,
  name,
  root,
  label,
  labelProps,
  isDisabled,
  helperText,
  helperProps,
  errorProps,
  hideError,
  isRequired,
  options,
  placeholder,
  size = 'md',
  backgroundColor = 'white',
  isClearable,
  text
}: FormInputPropsWithoutChildren<TFieldValues> & {
  options: {
    label: string;
    value: string;
  }[];
  backgroundColor?: SelectRootProps['backgroundColor'];
  placeholder?: string;
  isClearable?: boolean;
  text: {
    noOptionsFound?: string;
  };
}) => {
  const collection = createListCollection({ items: options });
  return (
    <Controller
      control={form.control}
      name={name}
      render={({ field }) => {
        return (
          <FormInput
            form={form}
            name={name}
            root={root}
            label={label}
            size={size}
            errorProps={errorProps}
            hideError={hideError}
            helperProps={helperProps}
            helperText={helperText}
            labelProps={labelProps}
            isRequired={isRequired}
            isDisabled={isDisabled}
          >
            <Select.Root
              collection={collection}
              value={field.value === null ? [] : [field.value]}
              onValueChange={({ value }) =>
                field.onChange(value === undefined || value.length === 0 ? null : value[0])
              }
              backgroundColor={backgroundColor}
              multiple={false}
            >
              <Select.Control>
                <Select.Trigger>
                  <Select.ValueText placeholder={placeholder} />
                </Select.Trigger>
                <Select.IndicatorGroup>
                  {isClearable && <SelectClearTrigger />}
                  <Select.Indicator />
                </Select.IndicatorGroup>
              </Select.Control>
              <Select.Positioner width={'full'}>
                <Select.Content w={'full'}>
                  <Select.ItemGroup>
                    {collection.items.map((option, index) => (
                      <Select.Item key={index} item={option}>
                        <Select.ItemText>{option.label}</Select.ItemText>
                        <Select.ItemIndicator>✓</Select.ItemIndicator>
                      </Select.Item>
                    ))}
                    {collection.items.length === 0 && (
                      <Box color={'gray.500'} px={2} py={1}>
                        {text.noOptionsFound}
                      </Box>
                    )}
                  </Select.ItemGroup>
                </Select.Content>
              </Select.Positioner>
            </Select.Root>
          </FormInput>
        );
      }}
    />
  );
};
