import { I18n, i18n } from '@lingui/core';
import { msg } from '@lingui/core/macro';
import { TagGroupType } from './type';

export const DEFAULT_TAGS: Record<
  TagGroupType,
  { label: (i18n: I18n) => string; getLabels: (i18n: I18n) => string[] }
> = {
  PROJECT: {
    label: i18n => i18n._(msg`Project`),
    getLabels: i18n => [i18n._(msg`Template`)]
  },
  CONSTRUCTION_PROJECT_OWNER: {
    label: i18n => i18n._(msg`Owner`),
    getLabels: i18n => []
  },
  CONSTRUCTION_PROJECT_ENGINEERING_OFFICE: {
    label: i18n => i18n._(msg`Engineering Office`),
    getLabels: i18n => []
  }
};
