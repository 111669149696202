'use client';

import { formatDate } from '@company/common/lib';
import { LocaleCodeType } from '@company/common/types';
import { Button, ButtonProps } from '../ui';
import { DatePickerPopover } from './popover';

export const DatePickerWithButtonTrigger = ({
  onChange,
  value,
  options,
  buttonProps,
  texts
}: {
  onChange: (date: Date | null) => void;
  value: Date | null;
  options: {
    placeholder: string;
    locale: LocaleCodeType;
  };
  buttonProps?: ButtonProps;
  texts: {
    clear: string;
  };
}) => {
  return (
    <DatePickerPopover onChange={onChange} value={value} options={options} texts={texts}>
      <Button {...buttonProps}>
        {value ? formatDate(value, 'MMM dd, yyyy', options.locale) : options.placeholder}
      </Button>
    </DatePickerPopover>
  );
};
