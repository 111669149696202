import { z } from 'zod';
import { EnumMap } from '../utils';
import { msg } from '@lingui/core/macro';

export const colorSchema = z.enum([
  'BLUE',
  'GREEN',
  'ORANGE',
  'PURPLE',
  'RED',
  'GRAY',
  'PINK',
  'CYAN',
  'TEAL',
  'YELLOW'
]);
export type Color = z.infer<typeof colorSchema>;

export const COLOR_MAP: EnumMap<Color, { palette: string }> = {
  BLUE: { id: 'BLUE', getLabel: t => t(msg`Blue`), palette: 'blue' },
  GREEN: { id: 'GREEN', getLabel: t => t(msg`Green`), palette: 'green' },
  ORANGE: { id: 'ORANGE', getLabel: t => t(msg`Orange`), palette: 'orange' },
  PURPLE: { id: 'PURPLE', getLabel: t => t(msg`Purple`), palette: 'purple' },
  RED: { id: 'RED', getLabel: t => t(msg`Red`), palette: 'red' },
  GRAY: { id: 'GRAY', getLabel: t => t(msg`Gray`), palette: 'gray' },
  PINK: { id: 'PINK', getLabel: t => t(msg`Pink`), palette: 'pink' },
  CYAN: { id: 'CYAN', getLabel: t => t(msg`Cyan`), palette: 'cyan' },
  TEAL: { id: 'TEAL', getLabel: t => t(msg`Teal`), palette: 'teal' },
  YELLOW: { id: 'YELLOW', getLabel: t => t(msg`Yellow`), palette: 'yellow' }
};

const pickColorHelper = (str: string, colors: Color[]): Color => {
  const index = str.split('').reduce((acc, char) => acc + char.charCodeAt(0), 0) % colors.length;
  return colors[index]!;
};

export const pickColor = (str: string): Color => {
  return pickColorHelper(str, Object.keys(COLOR_MAP) as Color[]);
};

export const randomColor = (): Color => {
  return pickColorHelper(Math.random().toString(), Object.keys(COLOR_MAP) as Color[]);
};

export const pickAvatarColor = (str: string): string => {
  const colors = (Object.keys(COLOR_MAP) as Color[]).filter(
    color => color !== 'GRAY' && color !== 'YELLOW'
  );
  return COLOR_MAP[pickColorHelper(str, colors)].palette;
};
