'use client';

import { Input, InputProps } from '@company/ui/components';
import { FieldValues } from '@company/ui/hooks/form';
import { FormInput, FormInputPropsWithoutChildren } from '.';
import { PasswordInput } from '../ui';

export const TextInput = <TFieldValues extends FieldValues>({
  form,
  name,
  root,
  label,
  labelProps,
  isDisabled,
  placeholder,
  helperText,
  helperProps,
  errorProps,
  hideError,
  isRequired,
  autoFocus,
  backgroundColor = 'white',
  size = 'md'
}: FormInputPropsWithoutChildren<TFieldValues> & {
  placeholder: InputProps['placeholder'];
  backgroundColor?: InputProps['backgroundColor'];
}) => {
  return (
    <FormInput
      form={form}
      name={name}
      root={root}
      label={label}
      size={size}
      errorProps={errorProps}
      hideError={hideError}
      helperProps={helperProps}
      helperText={helperText}
      labelProps={labelProps}
      isRequired={isRequired}
      isDisabled={isDisabled}
    >
      <Input
        placeholder={placeholder}
        background={backgroundColor}
        size={size}
        autoFocus={autoFocus}
        {...form.register(name)}
      />
    </FormInput>
  );
};

export const PasswordFieldInput = <TFieldValues extends FieldValues>({
  form,
  name,
  root,
  label,
  labelProps,
  isDisabled,
  placeholder = 'Enter password',
  helperText,
  helperProps,
  errorProps,
  hideError,
  size = 'md',
  autoFocus
}: FormInputPropsWithoutChildren<TFieldValues> & {
  placeholder: InputProps['placeholder'];
  isDisabled: InputProps['disabled'];
}) => {
  return (
    <FormInput
      form={form}
      name={name}
      root={root}
      label={undefined}
      size={size}
      errorProps={errorProps}
      hideError={hideError}
      helperProps={helperProps}
      helperText={helperText}
      labelProps={labelProps}
      isDisabled={isDisabled}
      autoFocus={autoFocus}
    >
      <>
        {label}
        <PasswordInput
          {...form.register(name)}
          placeholder={placeholder}
          size={size}
          autoFocus={autoFocus}
        />
      </>
    </FormInput>
  );
};
