import { msg } from '@lingui/core/macro';
import { z } from 'zod';
import { Color } from '../color';
import { EnumMap } from '../utils';

export const projectStatusSchema = z.enum(['PLANNED', 'IN_PROGRESS', 'COMPLETED', 'CANCELLED']);
export type ProjectStatus = z.infer<typeof projectStatusSchema>;

export const PROJECT_STATUSES: EnumMap<ProjectStatus, { color: Color }> = {
  PLANNED: { id: 'PLANNED', getLabel: t => t(msg`Planned`), color: 'GRAY' },
  IN_PROGRESS: { id: 'IN_PROGRESS', getLabel: t => t(msg`In Progress`), color: 'BLUE' },
  COMPLETED: { id: 'COMPLETED', getLabel: t => t(msg`Completed`), color: 'GREEN' },
  CANCELLED: { id: 'CANCELLED', getLabel: t => t(msg`Cancelled`), color: 'RED' }
};
