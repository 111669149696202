import { ColDef } from '@ag-grid-community/core';
import {
  CustomCellEditorProps,
  CustomCellRendererProps,
  CustomHeaderProps
} from '@ag-grid-community/react';
import { rowValueToString } from '@company/common/lib';
import { FieldType } from '@company/common/types';
import { Checkbox } from '@company/ui/components';
import {
  AsteriskIcon,
  BaselineIcon,
  BoxIcon,
  CalendarIcon,
  CheckIcon,
  CircleChevronDownIcon,
  FileIcon,
  HashIcon,
  LetterTextIcon,
  ListIcon
} from '@company/ui/icons';
import { TableRowDtoUi, ViewField } from '@components/table/types';
import { CellEditor, CellRenderer } from './cells';
import { ActionCell } from './cells/action';
import { useViewStore } from '@components/table/stores/view-store';

const addColor = 'var(--chakra-colors-green-200)';
const deleteColor = 'var(--chakra-colors-red-200)';

export const getColumnDef = (field: ViewField): ColDef<TableRowDtoUi> => {
  const commonColDef: ColDef<TableRowDtoUi> = {
    colId: field.id,
    headerName: field.name,
    width: field.width,
    editable: field.isEditable,
    headerComponentParams: {
      icon: fieldTypeToIcon[field.type],
      displayName: field.name
    },
    valueGetter: params => {
      const { data } = params;
      return rowValueToString(data?.[field.id]);
    },
    cellRenderer: (params: CustomCellRendererProps<TableRowDtoUi>) => {
      if (!params.data) {
        return null;
      }
      return <CellRenderer field={field} row={params.data} {...params} />;
    },
    cellEditor: (params: CustomCellEditorProps<TableRowDtoUi>) => {
      if (!field.isEditable || !params.data) {
        return null;
      }
      return <CellEditor field={field} row={params.data} {...params} />;
    },
    cellStyle: params => {
      if (params.data && params.data.proposedChange) {
        const proposedChange = params.data.proposedChange;
        if (proposedChange.type === 'DELETE') {
          return {
            backgroundColor: deleteColor
          };
        } else if (proposedChange.type === 'INSERT') {
          return {
            backgroundColor: addColor
          };
        } else if (proposedChange.type === 'UPDATE') {
          const newValue = rowValueToString(params.data[field.id]);
          const oldValue = rowValueToString(proposedChange.oldValue?.[field.id]);

          if (newValue !== oldValue) {
            if (newValue === null || newValue === '') {
              return {
                backgroundColor: deleteColor
              };
            } else {
              return {
                backgroundColor: addColor
              };
            }
          }
        }
      } else {
        return {
          backgroundColor: 'transparent'
        };
      }
    }
  };

  const fieldTypeToColDef: Record<FieldType, ColDef> = {
    LONG_TEXT: {},
    SHORT_TEXT: {},
    NUMBER: {},
    SINGLE_SELECT: {},
    MULTI_SELECT: {},
    DATE: {},
    CHECKBOX: {},
    FILE_ATTACHMENT: {},
    LINKED_ROW: {},
    OBJECT: {}
  };

  return {
    ...commonColDef,
    ...fieldTypeToColDef[field.type]
  };
};

export const expandColumnDef: ColDef<TableRowDtoUi> = {
  colId: 'expand',
  headerComponent: (params: CustomHeaderProps<TableRowDtoUi>) => {
    const { activeView } = useViewStore();
    if (!activeView.config.canDeleteRows) {
      return null;
    }
    return (
      <>
        <Checkbox
          variant={'solid'}
          colorPalette={'blue'}
          size={'xs'}
          checked={params.api.getSelectedNodes().length > 0}
          onCheckedChange={() => {
            if (params.api.getSelectedNodes().length > 0) {
              params.api.deselectAll();
            } else {
              params.api.selectAll();
            }
            params.api.redrawRows();
            params.api.refreshHeader();
          }}
        />
      </>
    );
  },
  width: 75,
  cellRenderer: (params: CustomCellEditorProps<TableRowDtoUi>) => {
    if (!params.data) {
      return null;
    }

    return (
      <ActionCell
        row={params.data}
        rowIndex={params.node.rowIndex}
        isSelected={params.node.isSelected() ?? false}
        setSelected={isSelected => {
          params.node.setSelected(isSelected);
          params.api.redrawRows({ rowNodes: [params.node] });
          params.api.refreshHeader();
        }}
      />
    );
  }
};

const fieldTypeToIcon: Record<FieldType, React.ElementType> = {
  LONG_TEXT: LetterTextIcon,
  SHORT_TEXT: BaselineIcon,
  NUMBER: HashIcon,
  SINGLE_SELECT: CircleChevronDownIcon,
  MULTI_SELECT: ListIcon,
  DATE: CalendarIcon,
  CHECKBOX: CheckIcon,
  FILE_ATTACHMENT: FileIcon,
  LINKED_ROW: AsteriskIcon,
  OBJECT: BoxIcon
};
