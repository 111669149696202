export const publicPaths = [
  '/sign-in',
  '/sign-up',
  '/forgot-password',
  '/reset-password',
  '/error',
  '/auth/confirm',
  '/auth/confirm-sso'
];

export const isPublicPath = (path: string) => {
  return publicPaths.some(p => path.startsWith(p));
};
