import { msg } from '@lingui/core/macro';
import { z } from 'zod';
import { EnumMap } from '../utils';

export const userRoleWithoutSuperAdminSchema = z.enum(['ADMIN', 'MEMBER']);
export const userRoleSchema = z.enum(['SUPER_ADMIN', 'ADMIN', 'MEMBER']);
export type UserRole = z.infer<typeof userRoleSchema>;

export const USER_ROLES: EnumMap<UserRole> = {
  SUPER_ADMIN: { id: 'SUPER_ADMIN', getLabel: t => t(msg`Super Admin`) },
  ADMIN: { id: 'ADMIN', getLabel: t => t(msg`Admin`) },
  MEMBER: { id: 'MEMBER', getLabel: t => t(msg`Member`) }
};
