'use client';

import { ColDef } from '@ag-grid-community/core';
import { moveArrayItem, replaceHandlebars, rowValueToString } from '@company/common/lib';
import { logger } from '@company/common/logger';
import { FieldTypeEnum, TableRowDataType } from '@company/common/types';
import { useData } from '@components/table/providers/data-provider';
import { useFieldStore } from '@components/table/stores/field-store';
import { useTableStore } from '@components/table/stores/table-store';
import { useViewStore } from '@components/table/stores/view-store';
import { TableRowDtoUi } from '@components/table/types';
import { TABLE_ROW_COLOR_LEVELS } from '@components/table/utils/styling';
import React from 'react';
import { expandColumnDef, getColumnDef } from './column-defs';
import { CustomGridHeader } from './header';
import { AgGridComponents, OpenedChildTableDialog, OpenedLinkedRowDialog } from './types';

interface IGridContext {
  columns: ColDef[];
  rows: TableRowDtoUi[];
  components: AgGridComponents;
  openedChildTableDialog: OpenedChildTableDialog | null;
  openedLinkedRowDialog: OpenedLinkedRowDialog | null;
  quickFilterText: string;
  onColumnResize: (fieldId: string, width: number) => void;
  onColumnMove: (fieldId: string, toIndex: number) => void;
  onOpenChildTable: (data: {
    childTableId: string;
    parentTableRowId: string;
    primaryFieldValue: TableRowDataType;
  }) => void;
  onOpenLinkedRowDialog: (data: { rowId: string; fieldId: string }) => void;
  onCloseChildTableDialog: () => void;
  onCloseLinkedRowDialog: () => void;
  getRowStyle: (rowId: string) => Partial<React.CSSProperties>;
  updateQuickFilterText: (text: string) => void;
  onClearCellValues: (cells: { rowId: string; fieldId: string }[]) => void;
  shouldCellBePreventedFromEditing: (fieldId: string) => boolean;
}

const GridContext = React.createContext<IGridContext | null>(null);

export const useGrid = () => {
  const context = React.useContext(GridContext);
  if (!context) {
    throw new Error('useGrid must be used within a GridProvider');
  }
  return context;
};

interface GridProviderProps {
  children: React.ReactNode;
}

export const GridProvider = ({ children }: GridProviderProps) => {
  const { rows, getRowById, isLeafRow, clearCellValues } = useData();
  const { activeView, updateActiveViewState } = useViewStore();
  const { table } = useTableStore();
  const { getFieldById, getPrimaryField } = useFieldStore();

  const [openedChildTableDialog, setOpenedChildTableDialog] =
    React.useState<OpenedChildTableDialog | null>(null);
  const [openedLinkedRowDialog, setOpenedLinkedRowDialog] =
    React.useState<OpenedLinkedRowDialog | null>(null);
  const [isQuickFilterActive, setIsQuickFilterActive] = React.useState(false);
  const [quickFilterText, setQuickFilterText] = React.useState('');

  const columns = React.useMemo(
    () => [
      expandColumnDef,
      ...activeView.state.fields.filter(field => !field.isHidden).map(getColumnDef)
    ],
    [activeView.state.fields]
  );

  const components = React.useMemo<AgGridComponents>(() => {
    return {
      agColumnHeader: CustomGridHeader
    };
  }, []);

  const onColumnResize = React.useCallback(
    (fieldId: string, width: number) => {
      updateActiveViewState(
        {
          fields: activeView.state.fields.map(field => {
            if (field.id === fieldId) {
              return { ...field, width };
            }
            return field;
          })
        },
        {
          shouldSaveToDb: true
        }
      );
    },
    [activeView.state.fields]
  );

  const onColumnMove = React.useCallback(
    (fieldId: string, toIndex: number) => {
      let toWithoutExpanded = toIndex - 1;
      const startIndex = activeView.state.fields.findIndex(field => field.id === fieldId);

      if (startIndex === toWithoutExpanded) {
        return;
      }

      const newFields = moveArrayItem(activeView.state.fields, startIndex, toWithoutExpanded);

      updateActiveViewState({
        fields: newFields
      });
    },
    [activeView.state.fields]
  );

  const getRowTitle = React.useCallback(
    (row: TableRowDtoUi) => {
      const rowTitleTemplate = table.rowTitleTemplate;
      let rowTitle = rowValueToString(row[getPrimaryField().id]);
      if (rowTitleTemplate) {
        try {
          rowTitle = replaceHandlebars(rowTitleTemplate, row) ?? rowTitle;
        } catch (error) {
          logger.error(error);
        }
      }
      return rowTitle;
    },
    [getPrimaryField]
  );

  const onOpenLinkedRowDialog = React.useCallback(
    (data: { rowId: string; fieldId: string }) => {
      const field = getFieldById(data.fieldId);
      const row = getRowById(data.rowId);

      if (!field || !row) {
        return;
      }

      if (field.type === FieldTypeEnum.LINKED_ROW) {
        if (!isLeafRow(row.id)) {
          return;
        }

        const linkedRowField = getFieldById(field.id);
        setOpenedLinkedRowDialog({
          tableId: table.id,
          fieldId: field.id,
          rowId: row.id,
          title: getRowTitle(row),
          linkedRowConfigType: linkedRowField.linkedRowConfig!.type
        });
      }
    },
    [setOpenedLinkedRowDialog, getFieldById, getRowById, getPrimaryField]
  );

  const onCloseLinkedRowDialog = React.useCallback(() => {
    setOpenedLinkedRowDialog(null);
  }, [setOpenedLinkedRowDialog]);

  const onOpenChildTable = React.useCallback(
    (data: {
      childTableId: string;
      parentTableRowId: string;
      primaryFieldValue: TableRowDataType;
    }) => {
      setOpenedChildTableDialog({
        childTableId: data.childTableId,
        parentTableRowId: data.parentTableRowId,
        title: getRowTitle(getRowById(data.parentTableRowId))
      });
    },
    [setOpenedChildTableDialog, getRowTitle, getRowById]
  );

  const onCloseChildTableDialog = React.useCallback(() => {
    setOpenedChildTableDialog(null);
  }, [setOpenedChildTableDialog]);

  const getRowStyle = React.useCallback(
    (rowId: string): Partial<React.CSSProperties> => {
      const row = getRowById(rowId);

      if (!row) {
        return {};
      }

      if (isLeafRow(row.id)) {
        return {};
      }

      const rowDepth = row.parentRowIdPath.length - 1;

      return {
        backgroundColor: TABLE_ROW_COLOR_LEVELS[rowDepth]
      };
    },
    [getRowById]
  );

  const onClearCellValues = React.useCallback(
    (cells: { rowId: string; fieldId: string }[]) => {
      clearCellValues(cells);
    },
    [clearCellValues]
  );

  const shouldCellBePreventedFromEditing = React.useCallback(
    (fieldId: string) => {
      const field = getFieldById(fieldId);
      return field?.type === FieldTypeEnum.LINKED_ROW;
    },
    [getFieldById]
  );

  const value = React.useMemo(
    () => ({
      columns,
      rows,
      components,
      openedChildTableDialog,
      openedLinkedRowDialog,
      isQuickFilterActive,
      quickFilterText,
      onColumnResize,
      onColumnMove,
      onCloseChildTableDialog,
      onOpenLinkedRowDialog,
      onCloseLinkedRowDialog,
      getRowStyle,
      updateQuickFilterText: setQuickFilterText,
      updateIsQuickFilterActive: setIsQuickFilterActive,
      onOpenChildTable,
      onClearCellValues,
      shouldCellBePreventedFromEditing
    }),
    [
      rows,
      columns,
      openedChildTableDialog,
      openedLinkedRowDialog,
      isQuickFilterActive,
      quickFilterText,
      onColumnResize,
      onColumnMove,
      setQuickFilterText,
      setIsQuickFilterActive,
      onOpenChildTable,
      onOpenLinkedRowDialog,
      onClearCellValues,
      shouldCellBePreventedFromEditing
    ]
  );

  return <GridContext.Provider value={value}>{children}</GridContext.Provider>;
};
