'use client';

import { getSignedDownloadUrlAction } from '@server/actions/file';

export const downloadFile = async ({ fileId }: { fileId: string }) => {
  const response = await getSignedDownloadUrlAction({ fileId });
  if (!response?.data) {
    throw new Error('Failed to get signed download url');
  }

  const { signedUrl, file } = response.data;

  // Fetch the file blob
  const fileBlob = await fetchFileBlob(signedUrl);

  // Download the file
  downloadFileBlob({ blob: fileBlob, name: file.name });
};

// Fetch the file as a blob
const fetchFileBlob = async (url: string) => {
  const response = await fetch(url);
  if (!response.ok) {
    throw new Error('Failed to fetch file from signed url');
  }
  return await response.blob();
};

// Create an anchor to download the file as a blob
export const downloadFileBlob = ({ blob, name }: { blob: Blob; name: string }) => {
  const link = document.createElement('a');
  const url = URL.createObjectURL(blob);
  link.href = url;
  link.download = name;
  document.body.appendChild(link);
  link.click();
  link.remove();
  URL.revokeObjectURL(url); // Clean up the URL object
};
