'use client';

import { AgGridReact } from '@ag-grid-community/react';
import { Box, Button, Center, CloseButton, Flex, Text } from '@company/ui/components';
import { useData } from '@components/table/providers/data-provider';
import { TableRowDtoUi } from '@components/table/types';
import { Trans } from '@lingui/react/macro';
import React from 'react';

interface GridActionBarProps {
  gridApiRef: React.RefObject<AgGridReact<TableRowDtoUi>> | null;
}

export const GridActionBar: React.FC<GridActionBarProps> = ({ gridApiRef }) => {
  const { deleteRows } = useData();

  const [selectedRowCount, setSelectedRowCount] = React.useState(0);

  React.useEffect(() => {
    if (!gridApiRef?.current?.api) return;
    const onSelectionChanged = () => {
      setSelectedRowCount(gridApiRef.current?.api.getSelectedNodes().length ?? 0);
    };

    gridApiRef.current?.api.addEventListener('selectionChanged', onSelectionChanged);

    return () => {
      gridApiRef.current?.api.removeEventListener('selectionChanged', onSelectionChanged);
    };
  }, [gridApiRef?.current?.api, setSelectedRowCount]);

  const onDeleteRows = () => {
    if (!gridApiRef?.current?.api) return;
    const selectedRows = gridApiRef.current?.api
      .getSelectedNodes()
      .filter(node => node.data !== undefined);

    gridApiRef.current.api.applyTransaction({
      remove: selectedRows.map(node => node.data!)
    });
    deleteRows(selectedRows.map(node => node.data).map(data => ({ id: data!.id })));
    gridApiRef.current?.api.deselectAll();
  };

  const onDeselectAll = () => {
    if (!gridApiRef?.current?.api) return;
    gridApiRef.current?.api.deselectAll();
    gridApiRef.current?.api.refreshHeader();
    gridApiRef.current?.api.refreshCells();
    setSelectedRowCount(0);
  };

  if (selectedRowCount === 0) {
    return null;
  }

  return (
    <Box position={'absolute'} bottom={12} left={'50%'} transform={'translateX(-50%)'}>
      <Flex shadow={'md'} rounded={'md'} borderWidth={1} p={3} alignItems={'center'} gap={4}>
        <Flex alignItems={'center'} gap={1.5}>
          <Center bgColor={'blue.500'} color={'white'} px={1.5} py={'2px'} rounded={'md'}>
            {selectedRowCount}
          </Center>{' '}
          <Trans>rows selected</Trans>
        </Flex>
        <Button variant={'surface'} colorPalette={'red'} size={'sm'} onClick={onDeleteRows} mr={-2}>
          <Trans>Delete Rows</Trans>
        </Button>
        <CloseButton size="sm" mr={-2} onClick={onDeselectAll} />
      </Flex>
    </Box>
  );
};
