'use client';

import { Box, Flex } from '@chakra-ui/react';

interface AppShellProps {
  sidebar?: React.ReactNode;
  main: React.ReactNode;
  breakpoint?: string;
}

export const AppShell: React.FC<AppShellProps> = ({ sidebar, main, breakpoint = 'md' }) => {
  return (
    <Flex h={'full'} w={'full'}>
      {sidebar && (
        <Box
          display={{ base: 'none', [breakpoint]: 'block' }}
          flexShrink={0}
          h={'full'}
          borderRightWidth={1}
          bgColor={'Sidebar'}
        >
          {sidebar}
        </Box>
      )}

      <Box w={'full'} bgColor={'Background'} overflow={'auto'}>
        {main}
      </Box>
    </Flex>
  );
};
