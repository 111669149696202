import {
  formatDate as formatDateFns,
  formatDistanceToNow as formatDistanceToNowFns
} from 'date-fns';
import { de, enUS } from 'date-fns/locale';
import { LocaleCodeType } from '../../types';

export const getDateFnsLocale = (localeCode: LocaleCodeType) => {
  if (localeCode === 'de') return de;
  return enUS;
};

export const formatDate = (date: Date, format: string, localeCode: LocaleCodeType) => {
  return formatDateFns(date, format, {
    locale: getDateFnsLocale(localeCode)
  });
};

export const formatDistanceToNow = (date: Date, localeCode: LocaleCodeType) => {
  return formatDistanceToNowFns(date, {
    addSuffix: true,
    locale: getDateFnsLocale(localeCode)
  });
};

export { isSameDay, subDays } from 'date-fns';
