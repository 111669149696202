import(/* webpackMode: "eager" */ "/usr/src/app/apps/platform/src/components/layout/sidebar/sidebar.tsx");
;
import(/* webpackMode: "eager" */ "/usr/src/app/apps/platform/src/components/layout/utils/page-container.tsx");
;
import(/* webpackMode: "eager" */ "/usr/src/app/apps/platform/src/components/layout/utils/sidebar-item.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["PieceOfInformationEditorProvider"] */ "/usr/src/app/apps/platform/src/providers/piece-of-information-editor-provider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["RecentPieceOfInformationProvider"] */ "/usr/src/app/apps/platform/src/providers/recent-piece-of-information-provider.tsx");
;
import(/* webpackMode: "eager" */ "/usr/src/app/packages/ui/src/components/index.tsx");
