import(/* webpackMode: "eager", webpackExports: ["AreYouSureDialogProvider"] */ "/usr/src/app/apps/platform/src/providers/are-you-sure-dialog-provider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["AuthProvider"] */ "/usr/src/app/apps/platform/src/providers/auth-user-provider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["I18nProvider"] */ "/usr/src/app/apps/platform/src/providers/i18n-provider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["LastPageProvider"] */ "/usr/src/app/apps/platform/src/providers/last-page-provider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SidebarProvider"] */ "/usr/src/app/apps/platform/src/providers/sidebar-provider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["TrpcProvider"] */ "/usr/src/app/apps/platform/src/providers/trpc-provider.tsx");
;
import(/* webpackMode: "eager" */ "/usr/src/app/node_modules/.pnpm/next@15.1.6_@babel+core@7.26.0_@opentelemetry+api@1.9.0_babel-plugin-macros@3.1.0_react-dom@1_say72wqx3h2a47n4y6tb6qnlgq/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"Inter\",\"arguments\":[{\"subsets\":[\"latin\"]}],\"variableName\":\"inter\"}");
;
import(/* webpackMode: "eager" */ "/usr/src/app/packages/ui/src/components/index.tsx");
;
import(/* webpackMode: "eager" */ "/usr/src/app/packages/ui/src/providers/design.tsx");
;
import(/* webpackMode: "eager" */ "/usr/src/app/packages/ui/src/styles/global.css");
