import { useMemo } from 'react';
import { Button, Table } from '..';
import { DAYS_PER_WEEK, isDatePartOfMonth, isToday } from './utils';
import { LocaleCodeType } from '@company/common/types';
import { formatDate } from '@company/common/lib';

const rows = 6;

export const DateSelectionView = ({
  year,
  month,
  value,
  onSelect,
  options
}: {
  year: number;
  month: number;
  value: Date | null;
  onSelect: (date: Date) => void;
  options: {
    locale: LocaleCodeType;
  };
}) => {
  const dateGrid = useMemo(() => {
    const dateGrid: Date[][] = [];
    let week: Date[] = [];

    const firstDayOfMonth = new Date(year, month, 1);
    const firstDayOfWeek = firstDayOfMonth.getDay();
    // Adjust for week starting on Monday
    const startOffset = firstDayOfWeek === 0 ? 6 : firstDayOfWeek - 1;

    const startDate = new Date(firstDayOfMonth);
    startDate.setDate(startDate.getDate() - startOffset);

    const totalDays = DAYS_PER_WEEK * rows;

    for (let i = 0; i < totalDays; i++) {
      week.push(new Date(startDate));
      startDate.setDate(startDate.getDate() + 1);

      if (week.length === DAYS_PER_WEEK) {
        dateGrid.push(week);
        week = [];
      }
    }

    return dateGrid;
  }, [year, month]);

  const isSelected = (date: Date) => {
    return (
      value?.getDate() === date.getDate() &&
      value?.getMonth() === date.getMonth() &&
      value?.getFullYear() === date.getFullYear()
    );
  };

  return (
    <Table.Root size={'sm'} unstyled h={'full'} w={'full'}>
      <Table.Header>
        <Table.Row color={'GrayText'} textAlign={'center'}>
          <Table.Cell>{formatDate(new Date(2024, 0, 1), 'EEE', options.locale)}</Table.Cell>
          <Table.Cell>{formatDate(new Date(2024, 0, 2), 'EEE', options.locale)}</Table.Cell>
          <Table.Cell>{formatDate(new Date(2024, 0, 3), 'EEE', options.locale)}</Table.Cell>
          <Table.Cell>{formatDate(new Date(2024, 0, 4), 'EEE', options.locale)}</Table.Cell>
          <Table.Cell>{formatDate(new Date(2024, 0, 5), 'EEE', options.locale)}</Table.Cell>
          <Table.Cell>{formatDate(new Date(2024, 0, 6), 'EEE', options.locale)}</Table.Cell>
          <Table.Cell>{formatDate(new Date(2024, 0, 7), 'EEE', options.locale)}</Table.Cell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {dateGrid.map((week, weekIndex) => (
          <Table.Row key={weekIndex}>
            {week.map((date, dateIndex) => (
              <Table.Cell
                onClick={() => onSelect(date)}
                fontWeight={'medium'}
                key={dateIndex}
                p={0}
              >
                <Button
                  size={'xs'}
                  w={'full'}
                  h={'full'}
                  p={0}
                  colorPalette={isSelected(date) ? 'primary' : undefined}
                  variant={isSelected(date) ? 'solid' : isToday(date) ? 'subtle' : 'ghost'}
                  color={
                    isSelected(date)
                      ? undefined
                      : isDatePartOfMonth(date, month)
                        ? 'black'
                        : 'gray.400'
                  }
                >
                  {date.getDate()}
                </Button>
              </Table.Cell>
            ))}
          </Table.Row>
        ))}
      </Table.Body>
    </Table.Root>
  );
};
