'use client';

import { TableDto, TableRowDto } from '@company/common/types';
import { createZustandContext } from '@stores/create';
import MiniSearch, { SearchResult } from 'minisearch';
import { create } from 'zustand';

interface InitialState {
  tables: TableDto[];
}

type TableQueryFilters = {
  isLeafRow: boolean;
  toExcludeIds: string[];
  toIncludeIds?: string[];
};

interface TableQueryStore {
  tables: TableDto[];
  tableSearchEngines: Record<string, MiniSearch<TableRowDto>>;
  tableToParentRowIds: Record<string, Set<string>>;
  updateTables: (tables: TableDto[]) => void;
  queryTableRows: (params: {
    tableId: string;
    fieldsIds: string[];
    searchQuery: string;
    filters: TableQueryFilters;
    limit: number;
  }) => {
    rows: TableRowDto[];
  };
}

type TableRowWithIsLeaf = TableRowDto & { isLeafRow: boolean };

export const [TableQueryStoreProvider, useTableQueryStore] = createZustandContext<
  InitialState,
  TableQueryStore
>(initial =>
  create<TableQueryStore>((set, get) => {
    const rebuildEnginesAndParentRows = (tables: TableDto[]) => {
      const tableSearchEngines = tables.reduce(
        (acc, table) => {
          const miniSearch = new MiniSearch<TableRowDto>({
            fields: table.fields.map(field => field.id)
          });

          miniSearch.addAll(table.rows);
          acc[table.id] = miniSearch;
          return acc;
        },
        {} as Record<string, MiniSearch<TableRowDto>>
      );

      const tableToParentRowIds = tables.reduce(
        (acc, table) => {
          const parentRowIds = new Set<string>(
            table.rows.filter(row => row.parentRowId).map(row => row.parentRowId!)
          );
          acc[table.id] = parentRowIds;
          return acc;
        },
        {} as Record<string, Set<string>>
      );

      return { tableSearchEngines, tableToParentRowIds };
    };

    const initialEnginesAndParentRows = rebuildEnginesAndParentRows(initial.tables);

    return {
      tables: initial.tables,
      tableSearchEngines: initialEnginesAndParentRows.tableSearchEngines,
      tableToParentRowIds: initialEnginesAndParentRows.tableToParentRowIds,
      updateTables: tables => {
        const { tableSearchEngines, tableToParentRowIds } = rebuildEnginesAndParentRows(tables);
        set(state => ({ ...state, tables, tableSearchEngines, tableToParentRowIds }));
      },
      queryTableRows: ({ tableId, fieldsIds, searchQuery, filters, limit }) => {
        const { tables, tableSearchEngines, tableToParentRowIds } = get();

        if (searchQuery.length < 1) {
          const table = tables.find(t => t.id === tableId);
          if (!table) {
            return { rows: [] };
          }
          return {
            rows: table.rows
              .map(row => ({
                ...row,
                isLeafRow: !tableToParentRowIds[tableId]!.has(row.id)
              }))
              .filter(filterRows(filters))
              .slice(0, limit)
          };
        }

        const miniSearch = tableSearchEngines[tableId]!;

        const searchResults = miniSearch.search(searchQuery, {
          fields: fieldsIds,
          prefix: true,
          fuzzy: 0.2
        });
        const idToSearchResult = searchResults.reduce(
          (acc, result) => {
            acc[result.id] = result;
            return acc;
          },
          {} as Record<string, SearchResult>
        );

        const table = tables.find(t => t.id === tableId);

        if (!table) {
          return { rows: [] };
        }

        const filteredRows = table.rows
          .map(row => ({
            ...row,
            isLeafRow: !tableToParentRowIds[tableId]!.has(row.id)
          }))
          .filter(row => idToSearchResult[row.id] !== undefined)
          .filter(filterRows(filters))
          .slice(0, limit)
          .sort(
            (a, b) =>
              searchResults.map(result => result.id).indexOf(a.id) -
              searchResults.map(result => result.id).indexOf(b.id)
          );

        return { rows: filteredRows };
      }
    };
  })
);

const filterRows = (filters: TableQueryFilters) => {
  return (row: TableRowWithIsLeaf) =>
    (row.isLeafRow || !filters.isLeafRow) &&
    !filters.toExcludeIds.includes(row.id) &&
    (filters.toIncludeIds?.includes(row.id) || !filters.toIncludeIds);
};
