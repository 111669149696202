import { TableRowDto } from '../../../types';
import { isReservedFieldId } from '../field';

export const mapTableRowFields = (
  row: TableRowDto,
  fieldIdMap: Record<string, string>
): TableRowDto => {
  return {
    id: row.id,
    parentRowId: row.parentRowId,
    parentTableRowId: row.parentTableRowId,
    primaryFieldValue: row.primaryFieldValue,
    proposedChange: row.proposedChange,
    ...Object.fromEntries(
      Object.entries(row)
        .filter(([fieldId]) => !isReservedFieldId(fieldId))
        .map(([fieldId, value]) => [fieldIdMap[fieldId], value])
    )
  };
};
