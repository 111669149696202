import { TagProps } from '@company/ui/components';

export const getStyleConfig = (
  recommendationScore: number | null
): {
  colorPalette: TagProps['colorPalette'];
  variant: TagProps['variant'];
} => {
  if (recommendationScore && recommendationScore > 75) {
    return {
      colorPalette: 'green',
      variant: 'solid'
    };
  }
  if (recommendationScore && recommendationScore > 50) {
    return {
      colorPalette: 'yellow',
      variant: 'solid'
    };
  }

  return {
    colorPalette: 'gray',
    variant: 'solid'
  };
};
