import { msg } from '@lingui/core/macro';
import { z } from 'zod';
import { PieceOfInformationType } from '../piece-of-information';
import { EnumMap } from '../utils';

export enum FileTagName {
  I_TWO_PROJECT_X83_FILE = 'i-two-project-x83-file',
  I_TWO_PROJECT_XML_FILE = 'i-two-project-xml-file'
}

export enum TableTagName {
  I_TWO_PROJECT_TABLE = 'i-two-project-table',
  I_TWO_COST_ESTIMATOR_WORK_ITEM_RECOMMENDATION_MEMORY = 'i-two-cost-estimator-work-item-recommendation-memory'
}

type TagName = FileTagName | TableTagName;

export const TAGS: EnumMap<
  TagName,
  {
    type: PieceOfInformationType;
  }
> = {
  [FileTagName.I_TWO_PROJECT_X83_FILE]: {
    id: FileTagName.I_TWO_PROJECT_X83_FILE,
    getLabel: t => t(msg`iTWO X83 File`),
    type: 'FILE'
  },
  [FileTagName.I_TWO_PROJECT_XML_FILE]: {
    id: FileTagName.I_TWO_PROJECT_XML_FILE,
    getLabel: t => t(msg`iTWO XML File`),
    type: 'FILE'
  },
  [TableTagName.I_TWO_PROJECT_TABLE]: {
    id: TableTagName.I_TWO_PROJECT_TABLE,
    getLabel: t => t(msg`iTWO Table`),
    type: 'TABLE'
  },
  [TableTagName.I_TWO_COST_ESTIMATOR_WORK_ITEM_RECOMMENDATION_MEMORY]: {
    id: TableTagName.I_TWO_COST_ESTIMATOR_WORK_ITEM_RECOMMENDATION_MEMORY,
    getLabel: t => t(msg`Karl's work item recommendation memory`),
    type: 'TABLE'
  }
};

export const fileTagNameSchema = z.enum(['i-two-project-x83-file', 'i-two-project-xml-file']);
export const tableTagNameSchema = z.enum(['i-two-project-table']);
export const pieceOfInformationTagNameSchema = z.union([fileTagNameSchema, tableTagNameSchema]);

export type PieceOfInformationTagName = z.infer<typeof pieceOfInformationTagNameSchema>;

export const tagScopeSchema = z.enum(['PROJECT', 'ORGANIZATION', 'CONVERSATION']);
export type TagScope = z.infer<typeof tagScopeSchema>;
