'use client';

import { AreYouSureDialog } from '@company/ui/components';
import React from 'react';

interface AreYouSureDialogProps {
  title: string;
  description: string;
  onConfirm: () => void;
  texts: {
    confirm: string;
    cancel: string;
    confirming: string;
  };
}

interface AreYouSureDialogContextProps {
  openAreYouSureDialog: (props: AreYouSureDialogProps) => void;
  closeAreYouSureDialog: () => void;
}

const DialogContext = React.createContext<AreYouSureDialogContextProps>({
  openAreYouSureDialog: () => {},
  closeAreYouSureDialog: () => {}
});

export const useAreYouSureDialog = () => React.useContext(DialogContext);

interface AreYouSureDialogProviderProps {
  children: React.ReactNode;
}

export const AreYouSureDialogProvider = ({ children }: AreYouSureDialogProviderProps) => {
  const [dialogProps, setDialogProps] = React.useState<AreYouSureDialogProps | null>(null);

  const openDialog = (props: AreYouSureDialogProps) => {
    setDialogProps(props);
  };

  const closeDialog = () => {
    setDialogProps(null);
  };

  const value = React.useMemo(
    () => ({ openAreYouSureDialog: openDialog, closeAreYouSureDialog: closeDialog }),
    [openDialog, closeDialog]
  );

  return (
    <DialogContext.Provider value={value}>
      {children}
      <AreYouSureDialog
        isOpen={!!dialogProps}
        onClose={closeDialog}
        title={dialogProps?.title ?? ''}
        description={dialogProps?.description ?? ''}
        onConfirm={dialogProps?.onConfirm ?? (() => {})}
        texts={dialogProps?.texts ?? { confirm: '', cancel: '', confirming: '' }}
      />
    </DialogContext.Provider>
  );
};
