import { rowValueToString } from '@company/common/lib';
import { TableRowLinkedRowType } from '@company/common/types';
import { Flex, IconButton, Tag, Tooltip } from '@company/ui/components';
import { BrainIcon, Maximize2Icon, PlusIcon, TriangleAlertIcon } from '@company/ui/icons';
import { useData } from '@components/table/providers/data-provider';
import { useLinkedRow } from '@components/table/providers/linked-row-provider';
import { Trans } from '@lingui/react/macro';
import React from 'react';
import { LinkedRowSearchPopover } from '../common';
import { getStyleConfig } from '../linked-row/utils';
import { useGrid } from '../provider';
import { CellRendererProps } from './types';

export const LinkedRowCell: React.FC<CellRendererProps> = props => {
  const { updateRow, isLeafRow: isLeafRowHelper } = useData();
  const { onOpenLinkedRowDialog } = useGrid();
  const { getLinkedRowWarningMessages, wasLinkedRowRememberedByAi } = useLinkedRow();

  const [isSearchPopoverOpen, setIsSearchPopoverOpen] = React.useState(false);

  const value = props.value as TableRowLinkedRowType | null | undefined;
  const cellRef = React.useRef<HTMLDivElement | null>(null);

  const isLeafRow = React.useMemo(
    () => isLeafRowHelper(props.rowId),
    [props.rowId, isLeafRowHelper]
  );

  const warningMessages = React.useMemo(
    () => getLinkedRowWarningMessages({ fieldId: props.field.id, rowId: props.rowId }),
    [props.field.id, props.rowId, value, getLinkedRowWarningMessages]
  );

  const wasRememberedByAi = React.useMemo(
    () => wasLinkedRowRememberedByAi({ fieldId: props.field.id, rowId: props.rowId }),
    [props.field.id, props.rowId, wasLinkedRowRememberedByAi, value]
  );

  const onRemove = React.useCallback(
    (linkedRowId: string) => {
      const nextValue: TableRowLinkedRowType = {
        linkedRows: value!.linkedRows.filter(row => row.linkedRowId !== linkedRowId),
        recommendationStatus: value!.recommendationStatus
      };
      updateRow({
        id: props.rowId,
        [props.field.id]: nextValue
      });
    },
    [props.rowId, props.field.id, updateRow, value]
  );

  const onMaximize = React.useCallback(() => {
    onOpenLinkedRowDialog({
      rowId: props.data.id,
      fieldId: props.field.id
    });
  }, [props.rowId, props.field.id, onOpenLinkedRowDialog]);

  const focusCell = React.useCallback(() => {
    const focusedCell = props.api.getFocusedCell();
    // We refocus the cell to ensure keyboard navigation works again from the focused cell,
    // since the popover makes ag grid lose the internal focus state.
    if (focusedCell) {
      props.api.setFocusedCell(focusedCell.rowIndex, focusedCell.column.getId());
    }
  }, [props.api]);

  const onMaximizeClick = React.useCallback(
    (e: React.MouseEvent) => {
      e.stopPropagation();
      e.preventDefault();
      onMaximize();
    },
    [onMaximize]
  );

  if (!value) {
    return null;
  }

  return (
    <Flex
      ref={cellRef}
      w={'full'}
      h={'full'}
      justify={'space-between'}
      align={'center'}
      gap={1}
      position={'relative'}
    >
      {value.linkedRows.length > 0 && (
        <Flex w={'full'} h={'full'} align={'center'} wrap={'wrap'} gap={1}>
          {value.linkedRows.map(({ linkedRowId, primaryFieldValue, recommendationScore }) => (
            <LinkedRowTag
              key={linkedRowId}
              linkedRowId={linkedRowId}
              primaryFieldValue={primaryFieldValue}
              recommendationScore={recommendationScore}
              onRemove={onRemove}
            />
          ))}
        </Flex>
      )}
      {wasRememberedByAi && (
        <Tooltip
          content={<Trans>This was learned by the AI-Agent</Trans>}
          portalled={true}
          lazyMount={true}
        >
          <IconButton
            size={'xs'}
            h={'6'}
            minH={'6'}
            w={'6'}
            minW={'6'}
            variant={'subtle'}
            colorPalette={'purple'}
            ml={'auto'}
          >
            <BrainIcon fontSize={'14px'} />
          </IconButton>
        </Tooltip>
      )}
      {warningMessages.length > 0 && (
        <Tooltip content={warningMessages.join(', ')} portalled={true} lazyMount={true}>
          <IconButton
            size={'xs'}
            h={'6'}
            minH={'6'}
            w={'6'}
            minW={'6'}
            variant={'subtle'}
            colorPalette={'orange'}
          >
            <TriangleAlertIcon fontSize={'14px'} />
          </IconButton>
        </Tooltip>
      )}
      {isLeafRow && props.field.isEditable && (
        <Flex h={'full'} className="display-on-cell-hover" align={'center'} gap={1} ml={'auto'}>
          <IconButton
            size={'xs'}
            h={'6'}
            minH={'6'}
            w={'6'}
            minW={'6'}
            variant={'subtle'}
            onClick={() => setIsSearchPopoverOpen(true)}
          >
            <PlusIcon fontSize={'14px'} />
          </IconButton>
          <IconButton
            aria-label="Remove"
            variant="surface"
            size="xs"
            onClick={onMaximizeClick}
            h={'6'}
            minH={'6'}
            w={'6'}
            minW={'6'}
            colorPalette={'primary'}
          >
            <Maximize2Icon fontSize={'13.5px'} />
          </IconButton>
        </Flex>
      )}
      {isSearchPopoverOpen && (
        <LinkedRowSearchPopover
          ref={cellRef}
          field={props.field}
          rowId={props.rowId}
          cellValue={value}
          isOpen={isSearchPopoverOpen}
          setIsOpen={setIsSearchPopoverOpen}
          returnFocus={focusCell}
        />
      )}
    </Flex>
  );
};

interface LinkedRowTagProps {
  linkedRowId: string;
  primaryFieldValue: string | number | boolean | Date | string[] | null;
  recommendationScore: number | null;
  onRemove: (linkedRowId: string) => void;
}

const LinkedRowTag = React.memo(
  ({ linkedRowId, primaryFieldValue, recommendationScore, onRemove }: LinkedRowTagProps) => {
    const rowValueAsString = React.useMemo(
      () => rowValueToString(primaryFieldValue),
      [primaryFieldValue]
    );
    const styleConfig = React.useMemo(
      () => getStyleConfig(recommendationScore),
      [recommendationScore]
    );

    const truncatedRowValueAsString = React.useMemo(() => {
      if (rowValueAsString.length > 35) {
        return `${rowValueAsString.slice(0, 32)}...`;
      }
      return rowValueAsString;
    }, [rowValueAsString]);

    const onRemoveClick = React.useCallback(
      (e: React.MouseEvent) => {
        e.stopPropagation();
        e.preventDefault();
        onRemove(linkedRowId);
      },
      [linkedRowId, onRemove]
    );

    return (
      <Tooltip
        content={`${recommendationScore ? `(${recommendationScore.toFixed(1)}%) ` : ''}${rowValueAsString}`}
        portalled={true}
        lazyMount={true}
      >
        <Tag
          size={'sm'}
          colorPalette={styleConfig.colorPalette}
          variant={styleConfig.variant}
          h={'18px'}
          closable={true}
          onClose={onRemoveClick}
          endElement={recommendationScore && <>{recommendationScore.toFixed(1)}%</>}
        >
          {truncatedRowValueAsString}
        </Tag>
      </Tooltip>
    );
  }
);
