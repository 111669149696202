'use client';

import { Tag } from '@company/ui/components';
import { getStyleConfig } from './utils';

export const LinkedRowTag = ({ score }: { score: number | null }) => {
  if (score === null) {
    return null;
  }
  const { colorPalette, variant } = getStyleConfig(score);
  return (
    <Tag colorPalette={colorPalette} variant={variant}>
      {score.toFixed(1)}%
    </Tag>
  );
};
