import { Center, Heading, Image, Stack } from '@company/ui/components';

export const AuthLayout = ({ children, title }: { children: React.ReactNode; title: string }) => {
  return (
    <Center maxW={'sm'} w={'full'} h={'full'} justifySelf={'center'} mt={-16}>
      <Stack alignItems={'center'} justifyContent={'center'} h={'full'} w={'full'} gap={6}>
        <Image
          src={'/logo-1024x1024.png'}
          alt={'App Logo'}
          width={10}
          height={10}
          rounded={'4xl'}
        />
        <Heading fontSize={'2xl'} color={'gray.700'} textAlign={'center'}>
          {title}
        </Heading>
        {children}
      </Stack>
    </Center>
  );
};
