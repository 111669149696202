'use client';

import { logger } from '@company/common/logger';
import {
  TABLE_EXPORT_FORMATS,
  TableDataType,
  TableDataTypeToExportFormat,
  tableDataTypeToExportFormats
} from '@company/common/types';
import { Box, Button, MenuContent, MenuItem, MenuRoot, MenuTrigger } from '@company/ui/components';
import { downloadFileBlob } from '@lib/file/download';
import { msg } from '@lingui/core/macro';
import { useLingui } from '@lingui/react';
import { Trans } from '@lingui/react/macro';
import React from 'react';
import { exportTableAction } from '../actions/export';

interface ExportTableButtonProps {
  table: {
    id: string;
    dataType: TableDataType | null;
    activeView: {
      id: string;
    };
  };
}

export const ExportTableButton = ({ table }: ExportTableButtonProps) => {
  const [isExporting, setIsExporting] = React.useState(false);

  const { _ } = useLingui();

  const exportFormats = React.useMemo(
    () => tableDataTypeToExportFormats(table.dataType),
    [table.dataType]
  );

  const onClick = async (value: TableDataTypeToExportFormat) => {
    setIsExporting(true);
    const response = await exportTableAction({
      tableId: table.id,
      viewId: table.activeView.id,
      format: value
    });

    if (!!response?.data) {
      // Decode the base64 string as UTF-8
      const byteCharacters = atob(response.data.fileContent);
      const byteNumbers = Array.from(byteCharacters, char => char.charCodeAt(0));
      const byteArray = new Uint8Array(byteNumbers);

      // Create the blob with the correct data
      const blob = new Blob([byteArray], { type: response.data.mimeType });
      downloadFileBlob({ blob, name: response.data.fileName });
    } else {
      logger.error('Failed to export table', { response });
    }
    setIsExporting(false);
  };

  return (
    <Box>
      <MenuRoot>
        <MenuTrigger asChild>
          <Button
            size={'xs'}
            variant={'subtle'}
            loading={isExporting}
            loadingText={_(msg`Exporting`)}
          >
            <Trans>Export</Trans>
          </Button>
        </MenuTrigger>
        {/* There is a weird bug that causes the menu to close if it's in a dialog/other portal */}
        <MenuContent portalled={false}>
          {exportFormats.map(format => (
            <MenuItem key={format} value={format} onClick={() => onClick(format)}>
              <Trans>
                Export as {TABLE_EXPORT_FORMATS[format].getLabel(_)}{' '}
                {`(.${TABLE_EXPORT_FORMATS[format].fileExtension})`}
              </Trans>
            </MenuItem>
          ))}
        </MenuContent>
      </MenuRoot>
    </Box>
  );
};
