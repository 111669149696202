import { logger } from '@company/common/logger';
import { I18n } from '@lingui/core';
import { useLingui } from '@lingui/react/macro';
import { useAuthUser } from '@providers/auth-user-provider';
import { AuthUser } from '@typings/auth-user';
import React from 'react';
import type { StoreApi, UseBoundStore } from 'zustand';

interface Context {
  authUser: AuthUser;
  i18n: I18n;
}

export const createZustandContext = <
  TInitialState,
  TState,
  TStore extends UseBoundStore<StoreApi<TState>> = UseBoundStore<StoreApi<TState>>
>(
  getStore: (initial: TInitialState, ctx: Context) => TStore
) => {
  const Context = React.createContext<TStore | null>(null);

  const Provider = (props: { children?: React.ReactNode; initialValue: TInitialState }) => {
    const { authUser } = useAuthUser();
    const { i18n } = useLingui();
    const [store] = React.useState(() => getStore(props.initialValue, { authUser, i18n }));

    React.useEffect(() => {
      store.setState(state => ({ ...state, ...props.initialValue }));
    }, [props.initialValue, store]);

    return <Context.Provider value={store}>{props.children}</Context.Provider>;
  };

  return [
    Provider,
    ((selector: Parameters<TStore>[0]) => {
      const store = React.useContext(Context);
      if (store === null) {
        logger.error('Missing provider for context:', Context);
        throw new Error('Missing provider for context');
      }
      return store(selector);
    }) as TStore
  ] as const;
};
