import { z } from 'zod';

export * from './information-request';
export * from './memory';

export const agentTypeSchema = z.enum(['COST_ESTIMATOR']);
export enum AgentTypeEnum {
  COST_ESTIMATOR = 'COST_ESTIMATOR'
}
export type AgentType = z.infer<typeof agentTypeSchema>;
