import { TableRowDto } from '@company/common/types';

export const getRowIdMaps = <TTableRow extends TableRowDto>({ rows }: { rows: TTableRow[] }) => {
  const parentMap: Record<string, string | null> = {};
  const childMap: Record<string, string[]> = {};

  rows.forEach(row => {
    const parentId = row.parentRowId ?? null;
    parentMap[row.id] = parentId;

    if (parentId) {
      if (!childMap[parentId]) {
        childMap[parentId] = [];
      }
      childMap[parentId].push(row.id);
    }
  });

  return { rowIdToParentRowId: parentMap, rowIdToChildRowIds: childMap };
};

export const getRowsWithPath = <TTableRow extends TableRowDto>({
  rows,
  rowIdToParentRowId
}: {
  rows: TTableRow[];
  rowIdToParentRowId: Record<string, string | null>;
}) => {
  const pathMap: Record<string, string[]> = {};

  const computePath = (rowId: string): string[] => {
    const parentRowId = rowIdToParentRowId[rowId];
    if (!parentRowId) {
      pathMap[rowId] = [rowId];
      return [rowId];
    }

    const parentPath = computePath(parentRowId);
    const path = [...parentPath, rowId];

    pathMap[rowId] = path;

    return path;
  };

  rows.forEach(row => {
    computePath(row.id);
  });

  return rows.map(row => ({
    ...row,
    parentRowIdPath: pathMap[row.id] ?? []
  }));
};

export const isLeafRow = (rowIdToChildRowIds: Record<string, string[]>, rowId: string) => {
  const childRowIds = rowIdToChildRowIds[rowId];
  return !childRowIds || childRowIds.length === 0;
};
