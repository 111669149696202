export const getConversationRealtimeChannelName = (conversationId: string) =>
  `conversation-${conversationId}`;

export const getConversationsChangeRealtimeChannelName = (userId: string) =>
  `conversations-change-${userId}`;

export const getConversationPieceOfInformationRealtimeChannelName = (conversationId: string) =>
  `conversation-piece-of-information-changes-${conversationId}`;

export const getProjectsRealtimeChannelName = (organizationId: string) =>
  `projects-${organizationId}`;

export const getProposedChangesRealtimeChannelName = (changeProposalId: string) =>
  `proposed-changes-${changeProposalId}`;

export const getProjectDashboardItemRealtimeChannelName = (projectId: string) =>
  `project-dashboard-item-${projectId}`;

export const getProjectDashboardItemUpdateProcessRealtimeChannelName = (projectId: string) =>
  `project-dashboard-item-update-process-${projectId}`;

export const getAgentSessionChangeRealtimeChannelName = (sessionId: string) =>
  `agent-session-${sessionId}`;
