import { z } from 'zod';

export const getSimilarRowsSchema = z.object({
  referencedTableIds: z.array(z.string()),
  table: z.object({
    id: z.string(),
    rowIds: z.array(z.string())
  }),
  toJoinTable: z.object({
    id: z.string(),
    rowIds: z.array(z.string())
  }),
  fields: z.array(
    z.union([
      z.object({
        referenceId: z.string(),
        weight: z.number()
      }),
      z.object({
        fieldId: z.string(),
        toJoinFieldId: z.string(),
        weight: z.number()
      })
    ])
  ),
  minSimilarity: z.number().optional(),
  topN: z.number(),
  ignoreEmptyValues: z.boolean().optional()
});
export type GetSimilarRowsArgs = z.infer<typeof getSimilarRowsSchema>;

export const getSimilarRowsOutputSchema = z.array(
  z.object({
    rowId: z.string(),
    similarRows: z.array(
      z.object({
        rowId: z.string(),
        similarity: z.number()
      })
    )
  })
);
export type GetSimilarRowsOutput = z.infer<typeof getSimilarRowsOutputSchema>;
