import { msg } from '@lingui/core/macro';
import { z } from 'zod';
import { EnumMap } from '../utils';

export const authenticationMethodsSchema = z.enum(['EMAIL_PASSWORD', 'SAML']);
export type AuthenticationMethod = z.infer<typeof authenticationMethodsSchema>;

export const AUTHENTICATION_METHODS: EnumMap<AuthenticationMethod> = {
  EMAIL_PASSWORD: {
    id: 'EMAIL_PASSWORD',
    getLabel: t => t(msg`Email & Password`)
  },
  SAML: {
    id: 'SAML',
    getLabel: t => t(msg`SAML`)
  }
};
