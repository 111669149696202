export const moveArrayItem = <T>(array: T[], from: number, to: number): T[] => {
  const newArray = [...array];
  const item = newArray.splice(from, 1)[0]!;
  newArray.splice(to, 0, item);
  return newArray;
};

export const addItemIf = <T, U>(item: U | null | undefined, itemFn: (item: U) => T): T[] => {
  const hasValue = !!item;
  return hasValue ? [itemFn(item!)] : [];
};

export const removeDuplicates = <T>(array: T[], keyExtractor: (item: T) => string): T[] => {
  const seen = new Set();
  return array.filter(item => {
    const key = keyExtractor(item);
    if (seen.has(key)) {
      return false;
    }
    seen.add(key);
    return true;
  });
};
