'use client';

import { createListCollection, ListCollection } from '@ark-ui/react';
import {
  Box,
  Center,
  ComboboxContentProps,
  ComboboxControl,
  ComboboxInput,
  ComboboxItem,
  ComboboxItemGroup,
  ComboboxList,
  ComboboxPositioner,
  ComboboxRoot,
  Flex,
  Spinner,
  Text
} from '@company/ui/components';
import React from 'react';
import { InputProps } from '../combobox/namespace';

interface SearchItem {
  id: string;
}

type SearchSize = 'sm' | 'md' | 'lg';

interface SearchProps<TSearchItem extends SearchItem> {
  inputValue: string;
  setInputValue: (value: string) => void;
  items: TSearchItem[];
  selectedItems: TSearchItem[];
  placeholder: string;
  renderItem: (
    item: TSearchItem,
    options: { isHovering: boolean; isSelected: boolean }
  ) => React.ReactNode;
  isLoading?: boolean;
  onSelect: (item: TSearchItem) => void;
  isOverlay?: boolean;
  size?: SearchSize;
  contentProps?: ComboboxContentProps;
  inputProps?: InputProps;
  debounceTime?: number;
  texts: {
    noResults: string;
    loading: string;
  };
  isCreateable?: boolean;
  renderCreateItem?: (inputValue: string) => React.ReactNode;
  onCreate?: (inputValue: string) => void;
}

export const Search = <TSearchItem extends SearchItem>({
  inputValue,
  setInputValue,
  items,
  selectedItems,
  renderItem,
  isLoading,
  onSelect: onValueChangeHandler,
  inputProps,
  contentProps,
  isOverlay = false,
  placeholder,
  texts,
  size = 'md',
  isCreateable = false,
  renderCreateItem,
  onCreate
}: SearchProps<TSearchItem>) => {
  const collection = React.useMemo(() => {
    return createListCollection<TSearchItem & { value: string }>({
      items: items.map(item => ({
        ...item,
        value: item.id
      }))
    });
  }, [items]);

  const onInputValueChange = ({ inputValue }: { inputValue: string }) => {
    setInputValue(inputValue);
  };

  return (
    <ComboboxRoot
      open={true}
      collection={collection}
      onValueChange={({ items }: { items: unknown[] }) => {
        onValueChangeHandler(items[0] as TSearchItem);
      }}
      onInputValueChange={onInputValueChange}
      inputValue={inputValue}
      placeholder={placeholder}
      size={size}
      inputBehavior="autohighlight"
      openOnClick={true}
      selectionBehavior="preserve"
      lazyMount={true}
    >
      <ComboboxControl size={size} mb={0}>
        <ComboboxInput {...inputProps} size={size} />
      </ComboboxControl>
      {isOverlay ? (
        <ComboboxPositioner size={size}>
          <Content
            collection={collection}
            inputValue={inputValue}
            renderItem={renderItem}
            selectedItems={selectedItems}
            size={size}
            texts={texts}
            isLoading={isLoading}
            contentProps={contentProps}
            isCreateable={isCreateable}
            renderCreateItem={renderCreateItem}
            onCreate={onCreate}
          />
        </ComboboxPositioner>
      ) : (
        <Content
          collection={collection}
          inputValue={inputValue}
          renderItem={renderItem}
          selectedItems={selectedItems}
          size={size}
          texts={texts}
          contentProps={contentProps}
          isLoading={isLoading}
          isCreateable={isCreateable}
          renderCreateItem={renderCreateItem}
          onCreate={onCreate}
        />
      )}
    </ComboboxRoot>
  );
};

const Content = <TSearchItem extends SearchItem>({
  collection,
  inputValue,
  selectedItems,
  renderItem,
  isLoading,
  size,
  contentProps,
  texts,
  isCreateable,
  renderCreateItem,
  onCreate
}: {
  collection: ListCollection<TSearchItem & { value: string }>;
  inputValue: string;
  selectedItems: TSearchItem[];
  renderItem: (
    item: TSearchItem,
    options: { isHovering: boolean; isSelected: boolean }
  ) => React.ReactNode;
  isLoading?: boolean;
  size: SearchSize;
  contentProps?: ComboboxContentProps;
  texts: {
    noResults: string;
    loading: string;
  };
  isCreateable?: boolean;
  renderCreateItem?: (inputValue: string) => React.ReactNode;
  onCreate?: (inputValue: string) => void;
}) => {
  return (
    <Box {...contentProps} overflow="auto" overscrollBehavior="contain">
      {/* Key is used to force re-render when the items change and reset the scroll position */}
      <ComboboxList size={size} key={collection.items.map(item => item.id).join(',')}>
        {collection.items.length === 0 &&
          !(isCreateable && renderCreateItem && inputValue.length > 0) && (
            <Center p="3">
              {isLoading ? (
                <Flex align="center" gap="2">
                  <Spinner size="sm" />
                  <Text color="fg.muted" textStyle={size} fontSize={'sm'}>
                    {texts.loading}
                  </Text>
                </Flex>
              ) : (
                <Text color="fg.muted" textStyle={size} fontSize={'sm'}>
                  {texts.noResults} "<Text as="strong">{inputValue}</Text>"
                </Text>
              )}
            </Center>
          )}
        {!isLoading &&
          isCreateable &&
          renderCreateItem &&
          onCreate &&
          inputValue.length > 0 &&
          collection.items.length === 0 && (
            <ComboboxItem
              height="auto"
              size={size}
              item={null}
              my={0.5}
              onClick={e => {
                e.stopPropagation();
                e.preventDefault();
                onCreate(inputValue);
              }}
            >
              {renderCreateItem(inputValue)}
            </ComboboxItem>
          )}
        {collection.items.length > 0 && (
          <ComboboxItemGroup>
            {collection.items.map(item => (
              <CustomComboboxItem
                key={item.id}
                item={item}
                renderItem={renderItem}
                size={size}
                isSelected={selectedItems.map(i => i.id).includes(item.id)}
              />
            ))}
          </ComboboxItemGroup>
        )}
      </ComboboxList>
    </Box>
  );
};

const CustomComboboxItem = <TSearchItem extends SearchItem>({
  item,
  renderItem,
  size,
  isSelected
}: {
  item: TSearchItem;
  isSelected: boolean;
  renderItem: (
    item: TSearchItem,
    options: { isHovering: boolean; isSelected: boolean }
  ) => React.ReactNode;
  size: SearchSize;
}) => {
  const [isHovering, setIsHovering] = React.useState(false);
  return (
    <ComboboxItem
      key={item.id}
      item={item}
      height="auto"
      size={size}
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
      px={3}
      mx={1}
      rounded="md"
    >
      {renderItem(item, { isHovering, isSelected })}
    </ComboboxItem>
  );
};
