import { formatNumber } from '@company/common/lib';
import { Text } from '@company/ui/components';
import { useAuthUser } from '@providers/auth-user-provider';
import { CellRendererProps } from './types';

export const NumberCell: React.FC<CellRendererProps> = props => {
  const { localeCode } = useAuthUser();
  const value = props.value as number | null | undefined;
  const numberConfig = props.field.numberConfig;

  return (
    <Text>
      {formatNumber(value, {
        fractionDigits: numberConfig?.decimalPlaces,
        locale: localeCode
      })}
    </Text>
  );
};
