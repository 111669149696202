import { z } from 'zod';
import { agentInformationRequestFieldWithValueSchema } from '../agent/information-request';
import { PieceOfInformationDto } from '../piece-of-information';
import { userTypeSchema } from '../user/type';
import { upsertConversationNodeElementSchema } from './element';

const textConversationNodeElementDtoSchema = z.object({
  type: z.literal('TEXT'),
  // StreamableValue needs record to avoid type error
  content: z.string().or(z.record(z.string()))
});

const artifactConversationNodeElementDtoSchema = z.object({
  type: z.literal('ARTIFACT'),
  artifacts: z.array(
    z.object({
      pieceOfInformationId: z.string(),
      changeProposalId: z.string().nullable(),
      viewId: z.string().nullish()
    })
  )
});

const taskRunStepConfirmButtonConversationNodeElementDtoSchema = z.object({
  type: z.literal('TASK_RUN_STEP_APPROVAL_BUTTON'),
  taskRunStep: z.object({
    id: z.string(),
    wasApproved: z.boolean()
  })
});

const formConversationNodeElementDtoSchema = z.object({
  type: z.literal('FORM'),
  inputFields: z.array(agentInformationRequestFieldWithValueSchema),
  taskRunId: z.string(),
  wasSubmitted: z.boolean(),
  submittedOn: z.date().optional()
});

const actionConversationNodeElementDtoSchema = z.object({
  type: z.literal('ACTION'),
  label: z.string().nullish(),
  pieceOfInformationId: z.string().nullable(),
  startedOn: z.date(),
  completedOn: z.date().optional(),
  errorMessage: z.string().optional()
});

const conversationNodeElementDtoSchema = z
  .object({
    id: z.string(),
    type: z.enum([
      'TEXT',
      'STREAMABLE_TEXT',
      'ARTIFACT',
      'TASK_RUN_STEP_APPROVAL_BUTTON',
      'FORM',
      'ACTION'
    ])
  })
  .and(
    z.discriminatedUnion('type', [
      textConversationNodeElementDtoSchema,
      artifactConversationNodeElementDtoSchema,
      taskRunStepConfirmButtonConversationNodeElementDtoSchema,
      formConversationNodeElementDtoSchema,
      actionConversationNodeElementDtoSchema
    ])
  );

export const agentTaskDtoSchema = z.object({
  id: z.string(),
  name: z.string(),
  description: z.string()
});

export const agentDtoSchema = z.object({
  id: z.string(),
  userId: z.string(),
  firstName: z.string(),
  lastName: z.string(),
  role: z.string(),
  description: z.string(),
  avatarUrl: z.string().nullable(),
  tasks: z.array(agentTaskDtoSchema)
});

export const conversationUserSchema = z.object({
  id: z.string(),
  type: userTypeSchema,
  title: z.string().nullable(),
  firstName: z.string(),
  lastName: z.string(),
  agent: agentDtoSchema.nullable()
});

export const conversationMemberSchema = z.object({
  id: z.string(),
  user: conversationUserSchema
});

export const conversationNodeDtoSchema = z.object({
  id: z.string(),
  previousNodeId: z.string().nullable(),
  sender: z.object({
    userId: z.string()
  }),
  elements: z.array(conversationNodeElementDtoSchema),
  createdOn: z.date()
});

export const upsertConversationNodeSchema = z.object({
  id: z.string(),
  sender: z.object({
    userId: z.string()
  }),
  elements: z.array(upsertConversationNodeElementSchema),
  previousNodeId: z.string().nullable(),
  createdOn: z.date()
});

export type TextConversationNodeElementDto = z.infer<typeof textConversationNodeElementDtoSchema>;
export type TaskRunStepConfirmButtonConversationNodeElementDto = z.infer<
  typeof taskRunStepConfirmButtonConversationNodeElementDtoSchema
>;
export type FormConversationNodeElementDto = z.infer<typeof formConversationNodeElementDtoSchema>;
export type ActionConversationNodeElementDto = z.infer<
  typeof actionConversationNodeElementDtoSchema
>;
export type ConversationNodeElementDto = z.infer<typeof conversationNodeElementDtoSchema>;
export type UpsertConversationNode = z.infer<typeof upsertConversationNodeSchema>;
export type ConversationNodeDto = z.infer<typeof conversationNodeDtoSchema> & {
  elements: ConversationNodeElementDto[];
};

export type ConversationMemberType = 'USER' | 'AGENT';
export type AgentSessionStatus = 'THINKING' | 'IDLE' | 'WORKING';

export type ConversationDto = {
  id: string;
  name: string;
  referencedPiecesOfInformation: PieceOfInformationDto[];
  nodes: ConversationNodeDto[];
  members: ConversationMemberDto[];
  project: ConversationProjectDto | null;
  knowledgeLibrary: { id: string };
};
export type ConversationMemberDto = z.infer<typeof conversationMemberSchema>;
export type AgentTaskDto = z.infer<typeof agentTaskDtoSchema>;
export type AgentDto = z.infer<typeof agentDtoSchema>;

export type ConversationProjectDto = {
  id: string;
  name: string;
  description: string | null;
};
