import { Box, Portal, Stack } from '@chakra-ui/react';
import { useDisclosure } from '@company/ui/hooks';
import { ChevronLeftIcon, ChevronRightIcon } from '@company/ui/icons';
import * as React from 'react';
import { ReactElement, useEffect, useRef, useState } from 'react';
import {
  Button,
  IconButton,
  PopoverBody,
  PopoverContent,
  PopoverRoot,
  PopoverTrigger
} from '../ui';
import { DateSelectionView } from './day-view';
import { MonthSelectionView } from './month-view';
import { YEAR_RANGE, getEndOfYearRange, getMonthName, getStartOfYearRange } from './utils';
import { YearSelectionView } from './year-view';
import { LocaleCodeType } from '@company/common/types';
import { formatDate } from '@company/common/lib';

export const DatePickerPopover = ({
  onChange = () => {},
  onCloseCb = () => {},
  defaultOpen = false,
  value,
  wrapInPortal = true,
  children,
  options,
  texts
}: {
  onChange: (date: Date | null) => void;
  onCloseCb?: () => void;
  defaultOpen?: boolean;
  value: Date | null;
  children: ReactElement;
  wrapInPortal?: boolean;
  options: {
    locale: LocaleCodeType;
  };
  texts: {
    clear: string;
  };
}) => {
  const { isOpen, onClose: onClosePopover, onOpenChange } = useDisclosure(defaultOpen);

  const onClose = React.useCallback(() => {
    onClosePopover();
    onCloseCb();
  }, [onClosePopover, onCloseCb]);

  const ref = useRef<HTMLDivElement>(null!);

  ({
    ref,
    handler: () => onClose()
  });

  const now = new Date();

  const [currentView, setCurrentView] = useState<'YEAR' | 'MONTH' | 'DAY'>('DAY');
  const [yearOffset, setYearOffset] = useState(0);
  const [year, setYear] = useState<number>(value ? value.getFullYear() : now.getFullYear());
  const [month, setMonth] = useState<number | null>(value ? value.getMonth() : now.getMonth());
  const [selectedValue, setSelectedValue] = useState<Date | null>(value ?? null);

  useEffect(() => {
    setCurrentView('DAY');
    setYearOffset(0);
    setYear(value ? value.getFullYear() : now.getFullYear());
    setMonth(value ? value.getMonth() : now.getMonth());
    setSelectedValue(value ?? null);
  }, [isOpen]);

  const goBack = () => {
    if (currentView === 'YEAR') {
      setYearOffset(yearOffset - 1);
    } else if (currentView === 'MONTH') {
      setYear(year - 1);
    } else {
      if (month! - 1 < 0) {
        setYear(year - 1);
        setMonth(11);
      } else {
        setMonth(month! - 1);
      }
    }
  };

  const goForward = () => {
    if (currentView === 'YEAR') {
      setYearOffset(yearOffset + 1);
    } else if (currentView === 'MONTH') {
      setYear(year + 1);
    } else {
      if (month! + 1 > 11) {
        setYear(year + 1);
        setMonth(0);
      } else {
        setMonth(month! + 1);
      }
    }
  };

  const handleButtonClick = () => {
    if (currentView === 'DAY') {
      setMonth(null);
      setCurrentView('MONTH');
    } else if (currentView === 'MONTH') {
      setCurrentView('YEAR');
    }
  };

  const onClear = () => {
    setSelectedValue(null);
    onChange(null);
    onClose();
  };

  const onSelect = (date: Date) => {
    setSelectedValue(date);
    onChange(date);
  };

  const centerYear = yearOffset * (YEAR_RANGE / 2) + year;

  const popoverContent = (
    <PopoverContent
      top={1}
      rounded={'lg'}
      shadow={'xl'}
      w={'360px'}
      h={'320px'}
      ref={ref}
      borderWidth={1}
      borderColor={'gray.200'}
    >
      <PopoverBody p={2} h={'full'} w={'full'}>
        <Stack w={'full'} h={'full'}>
          <Box w={'full'}>
            <Stack w={'full'} direction={'row'} justify={'space-between'} align={'center'}>
              <IconButton
                size={'xs'}
                aria-label={'Previous month'}
                variant={'ghost'}
                onClick={goBack}
              >
                <ChevronLeftIcon fontSize={'16px'} />
              </IconButton>
              <Button size={'xs'} variant={'ghost'} onClick={handleButtonClick}>
                {currentView === 'DAY'
                  ? `${formatDate(new Date(year, month!, 1), 'MMM', options.locale)} ${year!}`
                  : currentView === 'MONTH'
                    ? year!
                    : `${getStartOfYearRange(centerYear)} - ${getEndOfYearRange(centerYear)}`}
              </Button>
              <IconButton
                size={'xs'}
                aria-label={'Next month'}
                variant={'ghost'}
                onClick={goForward}
              >
                <ChevronRightIcon fontSize={'16px'} />
              </IconButton>
            </Stack>
          </Box>

          <Box flexGrow={1}>
            {currentView === 'DAY' && (
              <DateSelectionView
                year={year}
                month={month!}
                onSelect={onSelect}
                value={selectedValue}
                options={options}
              />
            )}
            {currentView === 'MONTH' && (
              <MonthSelectionView
                year={year}
                onSelect={month => {
                  setCurrentView('DAY');
                  setMonth(month);
                }}
                options={options}
              />
            )}
            {currentView === 'YEAR' && (
              <YearSelectionView
                centerYear={centerYear}
                onSelect={year => {
                  setCurrentView('MONTH');
                  setYear(year);
                }}
              />
            )}
          </Box>
          <Button size={'xs'} variant={'ghost'} onClick={onClear}>
            {texts.clear}
          </Button>
        </Stack>
      </PopoverBody>
    </PopoverContent>
  );

  return (
    <PopoverRoot
      open={isOpen}
      onOpenChange={onOpenChange}
      positioning={{ placement: 'bottom' }}
      size={'sm'}
      lazyMount={true}
    >
      <PopoverTrigger asChild>{children}</PopoverTrigger>
      {wrapInPortal ? <Portal>{popoverContent}</Portal> : popoverContent}
    </PopoverRoot>
  );
};
