'use client';

import { Dialog, Flex, Heading, Text } from '@chakra-ui/react';
import React from 'react';
import { Button } from '../ui';

interface AreYouSureDialogProps {
  title: string;
  description: string;
  onConfirm: () => void | Promise<void>;
  onCancel?: () => void | Promise<void>;
  isOpen: boolean;
  onClose: () => void;
  texts: {
    confirm: string;
    cancel: string;
    confirming: string;
  };
}

export const AreYouSureDialog = ({
  title,
  description,
  onConfirm,
  onCancel,
  isOpen,
  onClose,
  texts
}: AreYouSureDialogProps) => {
  const [isLoading, setIsLoading] = React.useState(false);

  return (
    <Dialog.Root open={isOpen} onOpenChange={onClose} size={'sm'} scrollBehavior={'inside'}>
      <Dialog.Backdrop />
      <Dialog.Positioner>
        <Dialog.Content>
          <Dialog.Header>
            <Heading fontSize={'xl'} mb={1} fontWeight={'medium'}>
              {title}
            </Heading>
          </Dialog.Header>
          <Dialog.CloseTrigger />
          <Dialog.Body>
            <Text color={'GrayText'}>{description}</Text>
          </Dialog.Body>
          <Dialog.Footer>
            {' '}
            <Flex justify={'end'} gap={3}>
              <Button
                onClick={async () => {
                  onCancel && (await onCancel());
                  onClose();
                }}
                variant={'subtle'}
                size={'sm'}
              >
                {texts.cancel}
              </Button>
              <Button
                onClick={async () => {
                  setIsLoading(true);
                  await onConfirm();
                  onClose();
                  setIsLoading(false);
                }}
                colorPalette={'primary'}
                size={'sm'}
                loading={isLoading}
                loadingText={texts.confirming}
              >
                {texts.confirm}
              </Button>
            </Flex>
          </Dialog.Footer>
        </Dialog.Content>
      </Dialog.Positioner>
    </Dialog.Root>
  );
};
