'use client';

import { CloseButton, Dialog, Heading, Text } from '@company/ui/components';
import { Table } from '@components/table';
import { RowSwitcher } from '../common/dialog-row-switcher';
import { OpenedChildTableDialog } from '../types';
import { useChildTable } from '@components/table/providers/child-table-provider';
import React from 'react';

interface ChildTableDialogProps {
  openedChildTableDialog: OpenedChildTableDialog | null;
  onClose: () => void;
}

export const ChildTableDialog = ({ openedChildTableDialog, onClose }: ChildTableDialogProps) => {
  const { getChildTableByParentTableRowId } = useChildTable();
  const childTable = React.useMemo(
    () =>
      openedChildTableDialog?.parentTableRowId &&
      getChildTableByParentTableRowId(openedChildTableDialog.parentTableRowId),
    [openedChildTableDialog?.parentTableRowId]
  );

  return (
    <Dialog.Root
      open={!!openedChildTableDialog}
      onOpenChange={({ open }) => {
        if (!open) {
          onClose();
        }
      }}
      size={'cover'}
      scrollBehavior={'inside'}
      placement={'center'}
    >
      <Dialog.Backdrop />
      <Dialog.Positioner>
        <Dialog.Content my={0} maxH={'100%'}>
          <Dialog.Header display={'flex'} gap={1} alignItems={'center'} pb={0} pt={2}>
            {openedChildTableDialog && (
              <RowSwitcher type={'child-table'} rowId={openedChildTableDialog.parentTableRowId} />
            )}
            <Heading fontSize={'lg'} fontWeight={'semibold'}>
              {openedChildTableDialog?.title}
            </Heading>
            <Text fontSize={'sm'} color={'GrayText'}></Text>
            <Dialog.CloseTrigger asChild>
              <CloseButton mt={-2} mr={-2} size={'sm'} ml={'auto'} onClick={onClose} />
            </Dialog.CloseTrigger>
          </Dialog.Header>
          <Dialog.Body>
            {openedChildTableDialog && childTable && <Table table={childTable} />}
          </Dialog.Body>
        </Dialog.Content>
      </Dialog.Positioner>
    </Dialog.Root>
  );
};
