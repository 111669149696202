'use client';

import { Box, Flex, Portal, Text } from '@chakra-ui/react';
import { Tag } from '@company/ui/components/ui/tag';
import { FieldValues } from '@company/ui/hooks/form';
import { SelectOption } from '@company/ui/types/select';
import React from 'react';
import { Controller } from 'react-hook-form';
import { FormInput, FormInputPropsWithoutChildren } from '.';
import { Combobox } from '../combobox';
import { TagsInput, TagsInputControlProps } from '../tags-input';
import { Skeleton } from '../ui';
import { createListCollection } from '@ark-ui/react';

interface AdditionalTagComboboxInputProps<T> {
  placeholder?: string;
  backgroundColor?: TagsInputControlProps['backgroundColor'];
  options: SelectOption<T>[];
  isMultiSelect?: boolean;
  text: {
    noOptionsFound: string;
  };
  renderItem?: (
    option: SelectOption<T>,
    options: {
      isLoading: boolean;
    }
  ) => React.ReactNode;
}

export const SyncTagComboboxInput = <TFieldValues extends FieldValues, T>(
  props: FormInputPropsWithoutChildren<TFieldValues> & AdditionalTagComboboxInputProps<T>
) => {
  const [inputValue, setInputValue] = React.useState<string>('');

  const options = React.useMemo(() => {
    return props.options.filter(option =>
      option.label.toLocaleLowerCase().includes(inputValue.toLocaleLowerCase())
    );
  }, [props.options, inputValue]);

  return (
    <AsyncTagComboboxInput
      {...props}
      options={options}
      inputValue={inputValue}
      onInputValueChange={setInputValue}
      isLoading={false}
      renderItem={props.renderItem ?? DefaultRenderItem}
    />
  );
};

export const AsyncTagComboboxInput = <TFieldValues extends FieldValues, T>({
  options,
  isMultiSelect,
  text,
  inputValue,
  onInputValueChange,
  backgroundColor,
  isLoading,
  renderItem = DefaultRenderItem,
  ...formInputProps
}: FormInputPropsWithoutChildren<TFieldValues> &
  AdditionalTagComboboxInputProps<T> & {
    onInputValueChange: (inputValue: string) => void;
    inputValue: string;
    isLoading: boolean;
  }) => {
  const valueToItem = React.useMemo(
    () =>
      options.reduce(
        (acc, option) => {
          acc[option.value] = option;
          return acc;
        },
        {} as Record<string, SelectOption<T>>
      ),
    [options]
  );

  const collection = React.useMemo(
    () =>
      createListCollection({
        items: options
          .filter(option => !option.isValueOnly)
          .map(option => ({
            label: option.label,
            value: option.value
          }))
      }),
    [options]
  );

  return (
    <FormInput {...formInputProps}>
      <Controller
        control={formInputProps.form.control}
        name={formInputProps.name}
        render={({ field }) => {
          const selectedValues: string[] = React.useMemo(() => {
            return field.value === null || field.value === undefined
              ? []
              : !isMultiSelect
                ? [field.value]
                : field.value;
          }, [field.value, isMultiSelect]);

          const removeItem = (itemValue: string) => {
            if (isMultiSelect) {
              field.onChange(selectedValues.filter(item => item !== itemValue));
            } else {
              field.onChange(null);
            }
          };

          return (
            <Combobox.Root
              asChild
              collection={collection}
              inputValue={inputValue}
              onInputValueChange={({ inputValue }) => onInputValueChange(inputValue)}
              onValueChange={({ value }) => {
                field.onChange(!isMultiSelect ? value[0] : value);
              }}
              value={selectedValues}
              readOnly={formInputProps.isReadOnly}
              disabled={formInputProps.isDisabled}
              openOnClick={true}
              multiple={isMultiSelect}
              closeOnSelect={true}
              selectionBehavior="clear"
            >
              <Combobox.Context>
                {comboBoxApi => (
                  <TagsInput.Root>
                    <TagsInput.Context>
                      {api => (
                        <>
                          <TagsInput.Control
                            {...comboBoxApi.getControlProps()}
                            backgroundColor={backgroundColor}
                            // Avoid changing the height when there are no selected values
                            py={selectedValues.length === 0 ? undefined : 1}
                          >
                            {selectedValues.map((value, index) => (
                              <TagsInput.Item key={index} index={index} value={value}>
                                <TagsInput.ItemPreview asChild>
                                  <Tag
                                    closable={
                                      !formInputProps.isReadOnly && !formInputProps.isDisabled
                                    }
                                    onClose={() => removeItem(value)}
                                    size={formInputProps.size}
                                  >
                                    {valueToItem[value]?.label}
                                  </Tag>
                                </TagsInput.ItemPreview>
                                <TagsInput.ItemInput />
                                <TagsInput.HiddenInput />
                              </TagsInput.Item>
                            ))}
                            {!formInputProps.isReadOnly && !formInputProps.isDisabled && (
                              <TagsInput.Input
                                {...comboBoxApi.getInputProps()}
                                placeholder={formInputProps.placeholder}
                                w={'full'}
                              />
                            )}
                          </TagsInput.Control>
                          <Portal>
                            <Combobox.Positioner zIndex={'9999 !important'}>
                              <Combobox.Content h={'full'} overflowY={'auto'} fontSize={'sm'}>
                                <Combobox.ItemGroup gap={1}>
                                  {collection.items.map(item => (
                                    <Combobox.Item key={item.value} item={item} py={1}>
                                      {renderItem(valueToItem[item.value]!, { isLoading })}
                                    </Combobox.Item>
                                  ))}
                                  {collection.items.length === 0 && (
                                    <Box color={'gray.500'} px={2} py={1}>
                                      {text.noOptionsFound}
                                    </Box>
                                  )}
                                </Combobox.ItemGroup>
                              </Combobox.Content>
                            </Combobox.Positioner>
                          </Portal>
                        </>
                      )}
                    </TagsInput.Context>
                  </TagsInput.Root>
                )}
              </Combobox.Context>
            </Combobox.Root>
          );
        }}
      />
    </FormInput>
  );
};

const DefaultRenderItem = <TFieldValues extends FieldValues, T>(
  option: SelectOption<T>,
  options: {
    isLoading: boolean;
  }
) => {
  return (
    <Flex alignItems={'center'} justifyContent={'space-between'} px={3}>
      <Combobox.ItemText h={'21px'}>
        <Flex w={'full'} h={'full'} gap={2} alignItems={'center'}>
          {option.startElement}
          <Flex w={'full'} h={'full'} alignItems={'center'}>
            {options.isLoading ? (
              <Skeleton w={'full'} h={3.5} />
            ) : (
              <Text lineClamp={1}>{option.label}</Text>
            )}
          </Flex>
        </Flex>
      </Combobox.ItemText>
      <Combobox.ItemIndicator>✓</Combobox.ItemIndicator>
    </Flex>
  );
};
