'use client';

import { TableRowDto, TableRowLinkedRowType } from '@company/common/types';
import { Button, Flex } from '@company/ui/components';
import { SearchIcon } from '@company/ui/icons';
import { useData } from '@components/table/providers/data-provider';
import { useFieldStore } from '@components/table/stores/field-store';
import { Trans } from '@lingui/react/macro';
import { ILinkedRowRecommendation } from '@typings/table';
import React from 'react';
import { LinkedRowSearchPopover } from '../common';

interface LinkedRowSearchProps {
  rowId: string;
  fieldId: string;
  onSelect: ({
    recommendation,
    item
  }: {
    recommendation: ILinkedRowRecommendation;
    item: TableRowDto;
  }) => void;
}

export const LinkedRowSearch = ({ rowId, fieldId, onSelect }: LinkedRowSearchProps) => {
  const { getFieldById } = useFieldStore();
  const { getValue } = useData();

  const field = getFieldById(fieldId);
  const value = React.useMemo(
    () => (getValue(rowId, fieldId) ?? { linkedRows: [] }) as TableRowLinkedRowType,
    [rowId, fieldId, getValue]
  );

  const [isSearchPopoverOpen, setIsSearchPopoverOpen] = React.useState(false);
  const buttonRef = React.useRef<HTMLButtonElement>(null);

  return (
    <Flex h={'48px'} alignItems={'center'} w={'full'} px={4}>
      <Button
        ref={buttonRef}
        size="sm"
        variant="outline"
        w={'full'}
        onClick={() => setIsSearchPopoverOpen(true)}
        position={'relative'}
        fontWeight={'normal'}
        justifyContent={'flex-start'}
      >
        <SearchIcon />
        <Trans>Search</Trans>
      </Button>
      <LinkedRowSearchPopover
        isOpen={isSearchPopoverOpen}
        setIsOpen={setIsSearchPopoverOpen}
        ref={buttonRef}
        cellValue={value}
        rowId={rowId}
        field={field}
        returnFocus={() => {}}
        isPortalled={false}
        onSelect={onSelect}
      />
    </Flex>
  );
};
