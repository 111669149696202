import { createSystem, defaultConfig, defineConfig, mergeConfigs } from '@chakra-ui/react';
import { comboboxSlotRecipe, tagsInputSlotRecipe } from './recipies';
import { inputRecipe } from './recipies/input';

const newConfig = defineConfig({
  globalCss: {
    '*::placeholder': {
      color: 'fg.muted'
    }
  },
  theme: {
    slotRecipes: {
      combobox: comboboxSlotRecipe,
      tagsInput: tagsInputSlotRecipe
    },
    tokens: {
      cursor: {
        button: { value: 'pointer' },
        checkbox: { value: 'pointer' },
        disabled: { value: 'pointer' },
        menuitem: { value: 'pointer' },
        option: { value: 'pointer' },
        radio: { value: 'pointer' },
        slider: { value: 'pointer' },
        switch: { value: 'pointer' }
      },
      colors: {
        primary: {
          50: { value: '#F5FBFF' },
          100: { value: '#EDF7FF' },
          200: { value: '#D1E8FF' },
          300: { value: '#B5D5FF' },
          400: { value: '#7CA4FC' },
          500: { value: '#4469fc' },
          600: { value: '#3656E3' },
          700: { value: '#263FBD' },
          800: { value: '#182D96' },
          900: { value: '#0E1D73' },
          950: { value: '#060F4A' },
          solid: { value: '#4469fc' },
          fg: { value: '#4469fc' },
          emphasized: { value: '#3656E3' },
          muted: { value: '#D1E8FF' },
          subtle: { value: '#F5FBFF' },
          focusRing: { value: '#4469fc' }
        }
      },
      fontWeights: {
        light: { value: '300' },
        normal: { value: '450' },
        medium: { value: '500' },
        semibold: { value: '600' },
        bold: { value: '700' }
      }
    },
    semanticTokens: {
      colors: {
        Sidebar: {
          value: '#fafafa'
        },
        Background: {
          value: '#fcfcfc'
        }
      }
    },
    recipes: {
      input: inputRecipe
    }
  }
});

const config = mergeConfigs(defaultConfig, newConfig);
const system = createSystem(config);

export { system };

export const getSystem = () => system;
