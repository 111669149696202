import { Tag as ChakraTag } from '@chakra-ui/react';
import * as React from 'react';

export interface TagProps extends ChakraTag.RootProps {
  startElement?: React.ReactNode;
  endElement?: React.ReactNode;
  onClose?: (e: React.MouseEvent) => void;
  closable?: boolean;
}

export const Tag = React.forwardRef<HTMLSpanElement, TagProps>(function Tag(props, ref) {
  const { startElement, endElement, onClose, closable = !!onClose, children, ...rest } = props;

  return (
    <ChakraTag.Root ref={ref} {...rest}>
      {startElement}
      <ChakraTag.Label>{children}</ChakraTag.Label>
      {endElement}
      {closable && (
        <ChakraTag.EndElement>
          <ChakraTag.CloseTrigger onClick={onClose} cursor="pointer" />
        </ChakraTag.EndElement>
      )}
    </ChakraTag.Root>
  );
});
