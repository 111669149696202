'use client';

import { Box, IconButton, Tooltip } from '@company/ui/components';
import { ChevronDownIcon, ChevronUpIcon } from '@company/ui/icons';
import { useData } from '@components/table/providers/data-provider';
import { useTableStore } from '@components/table/stores/table-store';
import { Trans } from '@lingui/react/macro';
import React from 'react';
import { useGrid } from '../provider';

export const RowSwitcher = ({
  type,
  rowId,
  fieldId
}: {
  type: 'child-table' | 'linked-row';
  rowId: string;
  fieldId?: string;
}) => {
  const { onOpenChildTable, onOpenLinkedRowDialog } = useGrid();
  const { table } = useTableStore();
  const { getPreviousLeafRow: getPreviousLeafRow, getNextLeafRow } = useData();

  const previousRow = React.useMemo(() => getPreviousLeafRow(rowId), [rowId, getPreviousLeafRow]);
  const nextRow = React.useMemo(() => getNextLeafRow(rowId), [rowId, getNextLeafRow]);

  const onPreviousRow = () => {
    if (!previousRow) return;
    if (type === 'child-table' && table.childTableId) {
      onOpenChildTable({
        childTableId: table.childTableId!,
        parentTableRowId: previousRow.id,
        primaryFieldValue: previousRow.primaryFieldValue
      });
    } else if (type === 'linked-row') {
      onOpenLinkedRowDialog({ rowId: previousRow.id, fieldId: fieldId! });
    }
  };

  const onNextRow = () => {
    if (!nextRow) return;
    if (type === 'child-table' && table.childTableId) {
      onOpenChildTable({
        childTableId: table.childTableId!,
        parentTableRowId: nextRow.id,
        primaryFieldValue: nextRow.primaryFieldValue
      });
    } else if (type === 'linked-row') {
      onOpenLinkedRowDialog({ rowId: nextRow.id, fieldId: fieldId! });
    }
  };

  return (
    <Box ml={-2}>
      <Tooltip content={<Trans>Previous Row</Trans>} positioning={{ placement: 'top' }}>
        <IconButton variant={'ghost'} size={'xs'} display={'block'} onClick={onPreviousRow} disabled={!previousRow}>
          <ChevronUpIcon />
        </IconButton>
      </Tooltip>
      <Tooltip content={<Trans>Next Row</Trans>}>
        <IconButton variant={'ghost'} size={'xs'} onClick={onNextRow} disabled={!nextRow}>
          <ChevronDownIcon />
        </IconButton>
      </Tooltip>
    </Box>
  );
};
