'use client';

import { formatDistanceToNow } from '@company/common/lib';
import { PieceOfInformationDtoWithStatus } from '@company/common/types';
import { Box, Card, CardRoot, Flex, Icon, IconButton, Spinner, Text } from '@company/ui/components';
import { CircleXIcon, TrashIcon } from '@company/ui/icons';
import { useAuthUser } from '@providers/auth-user-provider';
import { PIECE_OF_INFORMATION_TYPES } from '@typings/pieces-of-information';
import React from 'react';

interface PieceOfInformationItemProps {
  pieceOfInformation: PieceOfInformationDtoWithStatus;
  canRemovePieceOfInformation: boolean;
  removePieceOfInformation: (pieceOfInformationId: string) => Promise<void>;
  onClick: (pieceOfInformation: PieceOfInformationDtoWithStatus) => void;
}

export const PieceOfInformationItem = ({
  pieceOfInformation,
  canRemovePieceOfInformation,
  removePieceOfInformation,
  onClick
}: PieceOfInformationItemProps) => {
  const [isHovering, setIsHovering] = React.useState(false);
  const { localeCode } = useAuthUser();

  const timeElapsed = React.useMemo(() => {
    return formatDistanceToNow(new Date(pieceOfInformation.createdOn), localeCode);
  }, [pieceOfInformation.createdOn, localeCode]);

  const onRemovePieceOfInformation = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    await removePieceOfInformation(pieceOfInformation.id);
  };

  const PieceOfInformationIcon = PIECE_OF_INFORMATION_TYPES[pieceOfInformation.type].icon;

  return (
    <CardRoot
      w={'280px'}
      userSelect={'none'}
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
      _hover={{ cursor: 'pointer', bgColor: 'gray.50' }}
      onClick={() => onClick(pieceOfInformation)}
      minW={{ base: '280px', md: '360px' }}
      borderColor={pieceOfInformation.status === 'ERROR' ? 'red.500' : undefined}
    >
      <Card.Body py={1.5} pl={4} pr={2} w={'full'}>
        <Flex align={'center'} gap={4} h={'full'} w={'full'} minH={'32px'}>
          <Icon color={'gray.500'} fontSize={'sm'}>
            <PieceOfInformationIcon />
          </Icon>
          <Box>
            <Text
              maxW={{ base: '240px', md: '320px' }}
              fontWeight={'medium'}
              fontSize={'13px'}
              lineHeight={1.25}
              truncate
            >
              {pieceOfInformation.name}
            </Text>

            <Text fontSize={'xs'} color={'GrayText'} mt={-0.5}>
              {timeElapsed}
            </Text>
          </Box>
          <Box ml={'auto'} pr={2}>
            {isHovering &&
              canRemovePieceOfInformation &&
              pieceOfInformation.status === 'UPLOADED' && (
                <IconButton
                  variant={'outline'}
                  size={'xs'}
                  colorPalette={'red'}
                  onClick={onRemovePieceOfInformation}
                >
                  <TrashIcon />
                </IconButton>
              )}
            {pieceOfInformation.status === 'UPLOADING' && (
              <Spinner size={'xs'} color={'gray.500'} />
            )}
            {pieceOfInformation.status === 'ERROR' && <CircleXIcon color={'red.500'} />}
          </Box>
        </Flex>
      </Card.Body>
    </CardRoot>
  );
};
