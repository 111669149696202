import { z } from 'zod';

export * from './chunk';
export * from './project';
export * from './table';
export * from './tag';

export const knowledgeLibraryTypeSchema = z.enum(['ORGANIZATION', 'PROJECT', 'CONVERSATION', 'USER', 'TEAM']);
export type KnowledgeLibraryType = z.infer<typeof knowledgeLibraryTypeSchema>;

export const addToKnowledgeLibrarySchema = z.object({
  id: z.string(),
  tagNames: z.array(z.string()).optional(),
  isHidden: z.boolean()
});
export type AddToKnowledgeLibrary = z.infer<typeof addToKnowledgeLibrarySchema>;

export const fileKnowledgeLibraryConfigSchema = z
  .object({
    isHidden: z.boolean().optional()
  })
  .and(
    z.discriminatedUnion('type', [
      z.object({ type: z.literal('id'), id: z.string() }),
      z.object({ type: z.literal('useUserKnowledgeLibrary') }),
      z.object({ type: z.literal('useOrganizationKnowledgeLibrary') }),
      z.object({ type: z.literal('useTeamKnowledgeLibrary'), teamId: z.string() })
    ])
  );
export type FileKnowledgeLibraryConfig = z.infer<typeof fileKnowledgeLibraryConfigSchema>;

