'use client';

import { TablePieceOfInformationDto } from '@company/common/types';
import { Table } from '@components/table';
import { PieceOfInformationHeaderProps } from './types';

interface TablePieceOfInformationContentProps {
  table: TablePieceOfInformationDto;
  changeProposalId: string | null;
  header: PieceOfInformationHeaderProps;
}

export const TablePieceOfInformationContent = ({
  table: pieceOfInformationTable,
  changeProposalId,
  header
}: TablePieceOfInformationContentProps) => {
  return (
    <Table
      tableId={pieceOfInformationTable.id}
      changeProposalId={changeProposalId}
      header={header}
      viewId={pieceOfInformationTable.viewId}
    />
  );
};
