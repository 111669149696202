import { EnumMap, PieceOfInformationType } from '@company/common/types';
import {
  ArrowRightIcon,
  DownloadIcon,
  FileIcon,
  Maximize2Icon,
  MessageCircleIcon,
  ShapesIcon,
  SheetIcon
} from '@company/ui/icons';
import { msg, t } from '@lingui/core/macro';
import { I18n } from '@lingui/core';

export const PIECE_OF_INFORMATION_TYPES: EnumMap<
  PieceOfInformationType,
  {
    icon: React.ElementType;
    actionIcon: React.ElementType;
    color: string;
    colorPalette: string;
    backgroundColor: string;
    getLabel: (t: I18n['_']) => string;
    getLabelPlural: (t: I18n['_']) => string;
  }
> = {
  FILE: {
    id: 'FILE',
    icon: FileIcon,
    actionIcon: DownloadIcon,
    color: 'gray.600',
    colorPalette: 'gray',
    backgroundColor: 'gray.100',
    getLabel: t => t(msg`File`),
    getLabelPlural: t => t(msg`Files`)
  },
  TABLE: {
    id: 'TABLE',
    icon: SheetIcon,
    actionIcon: Maximize2Icon,
    color: 'green.500',
    colorPalette: 'green',
    backgroundColor: 'green.500',
    getLabel: t => t(msg`Table`),
    getLabelPlural: t => t(msg`Tables`)
  },
  PROJECT: {
    id: 'PROJECT',
    icon: ShapesIcon,
    actionIcon: ArrowRightIcon,
    color: 'blue.500',
    colorPalette: 'blue',
    backgroundColor: 'blue.500',
    getLabel: t => t(msg`Project`),
    getLabelPlural: t => t(msg`Projects`)
  },
  CONVERSATION: {
    id: 'CONVERSATION',
    icon: MessageCircleIcon,
    actionIcon: ArrowRightIcon,
    color: 'blue.500',
    colorPalette: 'blue',
    backgroundColor: 'blue.500',
    getLabel: t => t(msg`Connected Conversation`),
    getLabelPlural: t => t(msg`Connected Conversations`)
  }
};
