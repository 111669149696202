import { msg } from '@lingui/core/macro';
import { z } from 'zod';
import { EnumMap } from '../utils/enum-map';
import { getNativeEnumFromObject } from '../utils/zod-object-to-enum';

export enum FieldTypeEnum {
  SHORT_TEXT = 'SHORT_TEXT',
  LONG_TEXT = 'LONG_TEXT',
  NUMBER = 'NUMBER',
  SINGLE_SELECT = 'SINGLE_SELECT',
  MULTI_SELECT = 'MULTI_SELECT',
  DATE = 'DATE',
  CHECKBOX = 'CHECKBOX',
  FILE_ATTACHMENT = 'FILE_ATTACHMENT',
  LINKED_ROW = 'LINKED_ROW',
  OBJECT = 'OBJECT'
}

export type FieldType = keyof typeof FieldTypeEnum;

export const FIELD_TYPES: EnumMap<FieldType> = {
  SHORT_TEXT: { id: 'SHORT_TEXT', getLabel: t => t(msg`Short Text`) },
  LONG_TEXT: { id: 'LONG_TEXT', getLabel: t => t(msg`Long Text`) },
  NUMBER: { id: 'NUMBER', getLabel: t => t(msg`Number`) },
  SINGLE_SELECT: { id: 'SINGLE_SELECT', getLabel: t => t(msg`Single Select`) },
  MULTI_SELECT: { id: 'MULTI_SELECT', getLabel: t => t(msg`Multi Select`) },
  DATE: { id: 'DATE', getLabel: t => t(msg`Date`) },
  CHECKBOX: { id: 'CHECKBOX', getLabel: t => t(msg`Checkbox`) },
  FILE_ATTACHMENT: { id: 'FILE_ATTACHMENT', getLabel: t => t(msg`File Attachment`) },
  LINKED_ROW: { id: 'LINKED_ROW', getLabel: t => t(msg`Linked Row`) },
  OBJECT: { id: 'OBJECT', getLabel: t => t(msg`Object`) }
};
export const fieldTypeSchema = z.nativeEnum(getNativeEnumFromObject(FIELD_TYPES));

export const aiFieldMappingSchema = z.object({
  currentTableFieldId: z.string(),
  linkedTableFieldId: z.string(),
  scoreWeight: z.number(),
  shouldShowWarningIfNotExactMatch: z.boolean().optional()
});
export type AiFieldMapping = z.infer<typeof aiFieldMappingSchema>;

export const fieldLinkedRowConfigSchema = z.object({
  type: z.enum(['SINGLE_ROW', 'MULTIPLE_ROWS']),
  tableId: z.string(),
  useAi: z.boolean(),
  onlyLinkLeafRows: z.boolean(),
  aiConfig: z
    .object({
      fieldMappings: z.array(aiFieldMappingSchema),
      autofillIfScoreAbove: z.number().optional(),
      maxAutofilledRecommendations: z.number().optional()
    })
    .optional()
});
export type FieldLinkedRowConfig = z.infer<typeof fieldLinkedRowConfigSchema>;

export const fieldSelectConfigSchema = z.object({
  options: z.array(
    z.object({
      id: z.string(),
      value: z.string(),
      color: z.string()
    })
  )
});
export type FieldSelectConfig = z.infer<typeof fieldSelectConfigSchema>;

export const fieldNumberConfigSchema = z.object({
  min: z.number().optional(),
  max: z.number().optional(),
  decimalPlaces: z.number().optional()
});
export type FieldNumberConfig = z.infer<typeof fieldNumberConfigSchema>;

export const createTableFieldSchema = z.object({
  id: z.string().optional(),
  referenceId: z.string(),
  name: z.string(),
  type: fieldTypeSchema,
  isEditable: z.boolean(),
  isPrimary: z.boolean(),
  isRagSearchable: z.boolean(),
  linkedRowConfig: fieldLinkedRowConfigSchema.nullable(),
  selectConfig: fieldSelectConfigSchema.nullable(),
  numberConfig: fieldNumberConfigSchema.nullable()
});
export type CreateTableField = z.infer<typeof createTableFieldSchema>;

export const addFieldToTableSchema = z.object({
  name: z.string(),
  type: fieldTypeSchema,
  isEditable: z.boolean(),
  linkedRowConfig: fieldLinkedRowConfigSchema.nullable(),
  selectConfig: fieldSelectConfigSchema.nullable(),
  numberConfig: fieldNumberConfigSchema.nullable()
});
export type AddFieldToTable = z.infer<typeof addFieldToTableSchema>;

export const editFieldInTableSchema = z.object({
  id: z.string(),
  name: z.string(),
  linkedRowConfig: fieldLinkedRowConfigSchema.nullable(),
  selectConfig: fieldSelectConfigSchema.nullable(),
  numberConfig: fieldNumberConfigSchema.nullable()
});
export type EditFieldInTable = z.infer<typeof editFieldInTableSchema>;

export const deleteFieldSchema = z.object({
  id: z.string()
});
export type DeleteField = z.infer<typeof deleteFieldSchema>;

export const makeFieldPrimarySchema = z.object({
  id: z.string()
});
export type MakeFieldPrimary = z.infer<typeof makeFieldPrimarySchema>;

export const storedReservedFieldIds = ['id', 'parentRowId', 'parentTableRowId'] as const;
export const reservedFieldIds = [
  'id',
  'parentRowId',
  'parentTableRowId',
  'primaryFieldValue',
  'proposedChange'
] as const;
export const RESERVED_FIELDS: Record<
  (typeof reservedFieldIds)[number],
  {
    fieldId: string;
    fieldType: FieldType;
    storedInTable: boolean;
  }
> = {
  id: { fieldId: 'id', fieldType: 'SHORT_TEXT', storedInTable: true },
  parentRowId: { fieldId: 'parentRowId', fieldType: 'SHORT_TEXT', storedInTable: true },
  parentTableRowId: { fieldId: 'parentTableRowId', fieldType: 'SHORT_TEXT', storedInTable: true },
  primaryFieldValue: {
    fieldId: 'primaryFieldValue',
    fieldType: 'SHORT_TEXT',
    storedInTable: false
  },
  proposedChange: { fieldId: 'proposedChange', fieldType: 'OBJECT', storedInTable: false }
};
