export const replaceHandlebars = (template: string, data: Record<string, unknown>) => {
  try {
    return template.replace(/{{([^}]+)}}/g, (match, key) => {
      if (key.trim() in data) {
        return String(data[key.trim()] ?? '');
      }
      throw new Error(`Key ${key.trim()} not found in data`);
    });
  } catch (error) {
    return null;
  }
};
 