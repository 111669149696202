import { debounce } from 'lodash';
import React from 'react';

interface ProcessingListProps<T> {
  meetsConditions: (item: T) => boolean;
  onProcess: (item: T) => void;
  options?: {
    interval?: number;
    maxIterations?: number;
    debounceInMs?: number;
  };
}

export const useProcessingList = <T>({
  meetsConditions,
  onProcess,
  options
}: ProcessingListProps<T>) => {
  const processingList = React.useRef<T[]>([]);
  const iterationCount = React.useRef<number>(0);
  const intervalRef = React.useRef<NodeJS.Timeout | null>(null);

  const interval = options?.interval ?? 250;
  const maxIterations = options?.maxIterations ?? 50;
  const debounceInMs = options?.debounceInMs ?? 0;

  const processItems = React.useCallback(() => {
    if (processingList.current.length === 0 || iterationCount.current >= maxIterations) {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
        intervalRef.current = null;
      }
      return;
    }

    const remainingItems: T[] = [];
    processingList.current.forEach(item => {
      if (meetsConditions(item)) {
        onProcess(item);
      } else {
        remainingItems.push(item);
      }
    });

    processingList.current = remainingItems;
    iterationCount.current++;
  }, [maxIterations, meetsConditions, onProcess]);

  const startInterval = React.useCallback(() => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
    }
    iterationCount.current = 0;
    intervalRef.current = setInterval(processItems, interval);
  }, [interval, processItems]);

  const debouncedStartInterval = React.useMemo(
    () => debounce(startInterval, debounceInMs),
    [startInterval, debounceInMs]
  );

  const addToProcessingList = React.useCallback(
    (item: T) => {
      processingList.current.push(item);
      debouncedStartInterval();
    },
    [debouncedStartInterval]
  );

  React.useEffect(() => {
    return () => {
      if (intervalRef.current) clearInterval(intervalRef.current);
      debouncedStartInterval.cancel();
    };
  }, [debouncedStartInterval]);

  return { addToProcessingList };
};
