'use client';

import { Box, Center, Checkbox, Flex, IconButton, Text } from '@company/ui/components';
import { Maximize2Icon } from '@company/ui/icons';
import { useData } from '@components/table/providers/data-provider';
import { TableRowDtoUi } from '@components/table/types';
import React from 'react';
import { useGrid } from '../provider';
import { useTableStore } from '@components/table/stores/table-store';
import { useViewStore } from '@components/table/stores/view-store';

interface ActionCellProps {
  row: TableRowDtoUi;
  rowIndex: number | null;
  isSelected: boolean;
  setSelected: (isSelected: boolean) => void;
}

export const ActionCell: React.FC<ActionCellProps> = ({
  row,
  rowIndex,
  isSelected,
  setSelected
}) => {
  const { table } = useTableStore();
  const { activeView } = useViewStore();
  const { isLeafRow } = useData();
  const { onOpenChildTable } = useGrid();

  const isLeaf = React.useMemo(() => {
    return isLeafRow(row.id);
  }, [row, isLeafRow]);
  const childTableId = table.childTableId;

  const onCheckboxClick = () => {
    setSelected(!isSelected);
  };

  return (
    <Flex gap={2} align={'center'} h={'full'}>
      <Center
        minW={8}
        h={'full'}
        _hover={activeView.config.canDeleteRows ? { bg: 'bg.muted' } : undefined}
        ml={`-10px`}
        onClick={onCheckboxClick}
      >
        {rowIndex !== null && (
          <Text
            className={activeView.config.canDeleteRows ? 'hide-on-row-hover' : undefined}
            display={isSelected ? 'none' : 'block'}
            fontSize={'xs'}
            color={'GrayText'}
          >
            {rowIndex + 1}
          </Text>
        )}
        <Checkbox
          size={'xs'}
          variant={'solid'}
          colorPalette={'blue'}
          className={
            activeView.config.canDeleteRows && !isSelected ? 'display-on-row-hover' : undefined
          }
          display={isSelected ? 'block' : 'none'}
          checked={isSelected}
        />
      </Center>
      {isLeaf && childTableId !== null && (
        <Center className="display-on-row-hover">
          <IconButton
            size={'xs'}
            variant={'ghost'}
            h={6}
            w={6}
            minW={6}
            onClick={() =>
              onOpenChildTable({
                childTableId,
                parentTableRowId: row.id,
                primaryFieldValue: row.primaryFieldValue
              })
            }
            colorPalette={'primary'}
          >
            <Maximize2Icon fontSize={'sm'} />
          </IconButton>
        </Center>
      )}
    </Flex>
  );
};
