import { msg } from '@lingui/core/macro';
import { Color } from '../color';
import { EnumMap } from '../utils';

export type TaskRunStatus = 'IN_PROGRESS' | 'WAITING_FOR_USER_INPUT' | 'COMPLETED' | 'FAILED';

export const TASK_RUN_STATUSES: EnumMap<TaskRunStatus, { color: Color }> = {
  IN_PROGRESS: {
    id: 'IN_PROGRESS',
    getLabel: t => t(msg`In progress`),
    color: 'BLUE'
  },
  WAITING_FOR_USER_INPUT: {
    id: 'WAITING_FOR_USER_INPUT',
    getLabel: t => t(msg`Waiting for user input`),
    color: 'CYAN'
  },
  COMPLETED: {
    id: 'COMPLETED',
    getLabel: t => t(msg`Completed`),
    color: 'GREEN'
  },
  FAILED: {
    id: 'FAILED',
    getLabel: t => t(msg`Failed`),
    color: 'RED'
  }
};
