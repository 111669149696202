import { msg } from '@lingui/core/macro';
import { EnumMap } from '../utils';

export type Currency = 'EUR' | 'USD';

export const CURRENCIES: EnumMap<Currency, { symbol: string }> = {
  EUR: {
    id: 'EUR',
    symbol: '€',
    getLabel: t => t(msg`EUR`)
  },
  USD: {
    id: 'USD',
    symbol: '$',
    getLabel: t => t(msg`USD`)
  }
};
