'use client';

import { COMPANY_NAME } from '@company/common/constants';
import {
  Box,
  Button,
  Fieldset,
  Flex,
  Form,
  Link,
  PasswordFieldInput,
  Stack,
  Text,
  TextInput
} from '@company/ui/components';
import { UseFormReturn, useZodForm } from '@company/ui/hooks/form';
import { AuthLayout } from '@components/auth/layout';
import { SamlSignInButton } from '@components/auth/saml-sign-in-button';
import { msg } from '@lingui/core/macro';
import { useLingui } from '@lingui/react';
import { Trans } from '@lingui/react/macro';
import React from 'react';
import { determineSignInMethodAction, signInWithPasswordAction } from './actions';
import { SignInData, signInSchema, UserAuthenticationMethod } from './types';

export default function SignInPage() {
  const { _ } = useLingui();
  const form = useZodForm({
    schema: signInSchema,
    defaultValues: {
      email: '',
      password: ''
    }
  });

  const [userAuthenticationMethod, setUserAuthenticationMethod] =
    React.useState<UserAuthenticationMethod | null>(null);

  return (
    <AuthLayout title={_(msg`Login to ${COMPANY_NAME}`)}>
      <Fieldset.Root gap={6} invalid={!!form.formState.errors.root?.message}>
        {userAuthenticationMethod === null && (
          <EmailPage form={form} setUserAuthenticationMethod={setUserAuthenticationMethod} />
        )}
        {userAuthenticationMethod?.authenticationMethod === 'EMAIL_PASSWORD' && (
          <EmailAuthenticationPage form={form} goBack={() => setUserAuthenticationMethod(null)} />
        )}
        {userAuthenticationMethod?.authenticationMethod === 'SAML' && (
          <SamlAuthenticationPage
            form={form}
            organization={userAuthenticationMethod.organization}
            email={form.getValues('email')}
            goBack={() => setUserAuthenticationMethod(null)}
          />
        )}
        <Fieldset.ErrorText mt={0}>
          {form.formState.errors.root?.message ? _(form.formState.errors.root?.message) : null}
        </Fieldset.ErrorText>
      </Fieldset.Root>
    </AuthLayout>
  );
}

const EmailPage = ({
  form,
  setUserAuthenticationMethod
}: {
  form: UseFormReturn<SignInData>;
  setUserAuthenticationMethod: (method: UserAuthenticationMethod) => void;
}) => {
  const { _ } = useLingui();

  const [isLoading, setIsLoading] = React.useState(false);

  const onClickContinue = async () => {
    setIsLoading(true);
    form.setError('root', { message: '' });
    const response = await determineSignInMethodAction({ email: form.getValues('email') });
    if (response?.data) {
      setUserAuthenticationMethod(response.data);
    } else {
      form.setError('root', { message: response?.serverError });
    }
    setIsLoading(false);
  };

  return (
    <Form
      onSubmit={e => {
        e.preventDefault();
        onClickContinue();
      }}
    >
      <Stack gap={4} w={'full'}>
        <TextInput
          placeholder={_(msg`your@email.com`)}
          isDisabled={form.formState.isSubmitting}
          form={form}
          name={'email'}
          size={'md'}
          autoFocus
        />
        <Button
          w={'full'}
          type="submit"
          colorScheme={'primary'}
          colorPalette={'primary'}
          loading={isLoading}
          loadingText={
            <Text>
              <Trans>Continuing</Trans>
              ...
            </Text>
          }
        >
          <Trans>Continue</Trans>
        </Button>
      </Stack>
    </Form>
  );
};

interface AuthenticationPageProps {
  form: UseFormReturn<SignInData>;
  goBack: () => void;
}

const EmailAuthenticationPage = ({ form, goBack }: AuthenticationPageProps) => {
  const { _ } = useLingui();

  const onSubmit = async (data: SignInData) => {
    const result = await signInWithPasswordAction(data);
    if (result?.serverError) {
      form.setError('root', { message: result.serverError });
    }
  };

  return (
    <Form onSubmit={form.handleSubmit(onSubmit)}>
      <Stack gap={4} w={'full'}>
        <EmailInput form={form} />
        <PasswordFieldInput
          label={
            <Flex justify={'space-between'} w={'100%'} fontSize={'sm'}>
              <Box>
                <Trans>Password</Trans>
              </Box>
              <Box>
                <Link href="/forgot-password" fontSize={'xs'}>
                  <Trans>Forgot password</Trans>?
                </Link>
              </Box>
            </Flex>
          }
          placeholder={_(msg`Your password`)}
          isDisabled={form.formState.isSubmitting}
          form={form}
          name={'password'}
          autoFocus
          hideError={true}
        />
        {form.formState.errors.root?.message && (
          <Text fontSize={'sm'} color={'red.500'}>
            {form.formState.errors.root.message.toString()}
          </Text>
        )}
        <Button
          type="submit"
          w={'full'}
          colorScheme={'primary'}
          loading={form.formState.isSubmitting}
          disabled={form.formState.isSubmitting}
          loadingText={
            <Text>
              <Trans>Logging in</Trans>
              ...
            </Text>
          }
          colorPalette={'primary'}
        >
          <Trans>Login</Trans>
        </Button>
        <BackToLoginButton goBack={goBack} />
      </Stack>
    </Form>
  );
};

const SamlAuthenticationPage = ({
  organization,
  email,
  form,
  goBack
}: {
  organization: {
    name: string;
    samlProviderId: string;
    logoUrl: string;
  };
  email: string;
  form: UseFormReturn<SignInData>;
  goBack: () => void;
}) => {
  return (
    <Stack gap={4}>
      <EmailInput form={form} />
      <SamlSignInButton organization={organization} userEmail={email} />
      <BackToLoginButton goBack={goBack} />
    </Stack>
  );
};

const EmailInput = ({ form }: { form: UseFormReturn<SignInData> }) => {
  const { _ } = useLingui();

  return (
    <TextInput
      label={_(msg`Email`)}
      placeholder={_(msg`your@email.com`)}
      isDisabled={true}
      form={form}
      name={'email'}
      size={'md'}
    />
  );
};

const BackToLoginButton = ({ goBack }: { goBack: () => void }) => {
  return (
    <Button variant={'ghost'} onClick={goBack}>
      <Trans>Back to login</Trans>
    </Button>
  );
};
