'use client';

import { Box, Container, ContainerProps, Flex, PageHeading, Stack } from '@company/ui/components';
import { PageContainerProvider } from '@providers/page-container-provider';
import React from 'react';

interface PageContainerProps {
  children: React.ReactElement;
  containerProps?: ContainerProps;
  heading?: {
    title: {
      icon?: React.ReactNode;
      text: string;
    };
    subTitle?: string | null;
    rightElement?: React.ReactNode;
  };
  appbar?: React.ReactNode;
}

export const PageContainer = (props: PageContainerProps) => {
  return (
    <PageContainerProvider>
      <PageContainerInsideProvider {...props} />
    </PageContainerProvider>
  );
};

const PageContainerInsideProvider = ({
  children,
  containerProps,
  heading: pageHeading,
  appbar
}: PageContainerProps) => {
  return (
    <Stack w={'full'} h={'full'} gap={0}>
      {appbar && (
        <Flex minH={'48px'} w={'full'} align={'center'} gap={2} px={4}>
          {appbar}
        </Flex>
      )}
      <Box h={'full'} w={'full'} flexGrow={1} flexShrink={1} flexBasis={0}>
        <Container
          w={'full'}
          h={'full'}
          pb={10}
          pt={appbar ? 4 : 10}
          maxW={'3xl'}
          {...containerProps}
        >
          <Stack w={'full'} gap={6} h={'full'}>
            {pageHeading && <PageHeading {...pageHeading} />}
            <Box h={'full'}>{children}</Box>
          </Stack>
        </Container>
      </Box>
    </Stack>
  );
};
