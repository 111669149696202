import { z } from 'zod';
import { colorSchema } from '../color';
import { tagGroupTypeSchema } from './type';

export const upsertTagSchema = z
  .object({
    id: z.string().optional(),
    label: z.string(),
    color: colorSchema,
    groupId: z.string().optional(),
    groupType: tagGroupTypeSchema.optional()
  })
  .refine(data => (data.groupId !== undefined) !== (data.groupType !== undefined), {
    message: 'Either groupId or groupType must be provided, but not both'
  });

export type UpsertTag = z.infer<typeof upsertTagSchema>;
