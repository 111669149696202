import { debounce } from 'lodash';
import React from 'react';

interface BatchProcessingProps<T> {
  debounceInMs?: number;
  onProcess: (items: T[]) => void;
}

export const useDebouncedBatchProcessing = <T>({
  debounceInMs = 1_000,
  onProcess
}: BatchProcessingProps<T>) => {
  const processingList = React.useRef<T[]>([]);

  const startProcessing = React.useCallback(
    debounce(() => {
      onProcess(processingList.current);
      processingList.current = [];
    }, debounceInMs),
    [debounceInMs, onProcess]
  );

  const addToProcessingList = React.useCallback(
    (item: T) => {
      processingList.current.push(item);
      startProcessing();
    },
    [startProcessing]
  );

  return { addToProcessingList };
};
