'use client';

import React from 'react';

export const useNavigator = () => {
  const [navigatorObj, setNavigatorObj] = React.useState<Navigator | null>(null);

  React.useEffect(() => {
    if (typeof window !== 'undefined') {
      setNavigatorObj(window.navigator);
    }
  }, []);

  return navigatorObj;
};
