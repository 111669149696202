import { CustomCellEditorProps, CustomCellRendererProps } from '@ag-grid-community/react';
import { Box, Flex } from '@company/ui/components';
import { TableRowDtoUi, ViewField } from '@components/table/types';
import React from 'react';
import { CheckboxCell } from './checkbox';
import { DateCell } from './date';
import { FileAttachmentCell } from './file-attachment';
import { LinkedRowCell } from './linked-row';
import { LongTextCell } from './long-text';
import { MultiSelectCell } from './multi-select';
import { NumberCell } from './number';
import { ObjectCell } from './object';
import { SingleLineTextCell } from './single-line-text';
import { SingleSelectCell } from './single-select';
import { CellEditorProps, CellRendererProps } from './types';

interface InputCellRendererProps extends CustomCellRendererProps {
  row: TableRowDtoUi;
  field: ViewField;
}

export const CellRenderer: React.FC<InputCellRendererProps> = props => {
  const propsWithValue: CellRendererProps = React.useMemo(
    () => ({
      ...props,
      value: props.row[props.field.id],
      rowId: props.row.id
    }),
    [props]
  );

  const fieldTypeToCell: Record<ViewField['type'], React.ReactElement> = React.useMemo(
    () => ({
      LONG_TEXT: <LongTextCell {...propsWithValue} />,
      SHORT_TEXT: <SingleLineTextCell {...propsWithValue} />,
      NUMBER: <NumberCell {...propsWithValue} />,
      SINGLE_SELECT: <SingleSelectCell {...propsWithValue} />,
      MULTI_SELECT: <MultiSelectCell {...propsWithValue} />,
      DATE: <DateCell {...propsWithValue} />,
      CHECKBOX: <CheckboxCell {...propsWithValue} />,
      FILE_ATTACHMENT: <FileAttachmentCell {...propsWithValue} />,
      LINKED_ROW: <LinkedRowCell {...propsWithValue} />,
      OBJECT: <ObjectCell {...propsWithValue} />
    }),
    [propsWithValue]
  );
  const cell = React.useMemo(
    () => fieldTypeToCell[propsWithValue.field.type],
    [propsWithValue.field.type, fieldTypeToCell]
  );

  return (
    <Box w={'full'} h={'full'}>
      <Flex align={'center'} h={'full'} w={'full'} fontSize={'xs'}>
        {cell}
      </Flex>
    </Box>
  );
};

interface InputCellEditorProps extends CustomCellEditorProps {
  field: ViewField;
  row: TableRowDtoUi;
}

export const CellEditor: React.FC<InputCellEditorProps> = props => {
  const propsWithValue: CellEditorProps = React.useMemo(
    () => ({
      ...props,
      value: props.data[props.field.id],
      rowId: props.data.id
    }),
    [props]
  );

  const fieldTypeToCell: Record<ViewField['type'], React.ReactElement> = React.useMemo(
    () => ({
      LONG_TEXT: <></>,
      SHORT_TEXT: <></>,
      NUMBER: <></>,
      SINGLE_SELECT: <></>,
      MULTI_SELECT: <></>,
      DATE: <></>,
      CHECKBOX: <></>,
      FILE_ATTACHMENT: <></>,
      LINKED_ROW: <></>,
      OBJECT: <></>
    }),
    [propsWithValue]
  );
  const cell = React.useMemo(
    () => fieldTypeToCell[propsWithValue.field.type],
    [propsWithValue.field.type, fieldTypeToCell]
  );

  return (
    <Box w={'full'} h={'full'}>
      <Flex align={'center'} h={'full'} w={'full'} fontSize={'xs'}>
        {cell}
      </Flex>
    </Box>
  );
};
