'use client';

import { isTableRowLinkedRowTypeArray } from '@company/common/lib';
import { TableRowLinkedRowType } from '@company/common/types';
import { Box, CloseButton, Dialog, Heading, Text } from '@company/ui/components';
import { useData } from '@components/table/providers/data-provider';
import { Trans } from '@lingui/react/macro';
import React from 'react';
import { RowSwitcher } from '../common/dialog-row-switcher';
import { OpenedLinkedRowDialog } from '../types';
import { LinkedRowBody } from './content';
import { useFieldStore } from '@components/table/stores/field-store';

interface LinkedRowDialogProps {
  openedLinkedRowDialog: OpenedLinkedRowDialog | null;
  onClose: () => void;
}

export const LinkedRowDialog = ({ openedLinkedRowDialog, onClose }: LinkedRowDialogProps) => {
  return (
    <Dialog.Root
      open={!!openedLinkedRowDialog}
      onOpenChange={({ open }) => {
        if (!open) {
          onClose();
        }
      }}
      size={'cover'}
      scrollBehavior={'inside'}
      placement={'center'}
    >
      <Dialog.Backdrop />
      <Dialog.Positioner>
        <Dialog.Content my={0} maxH={'100%'}>
          <Dialog.Header display={'flex'} gap={1} alignItems={'center'} pb={0} pt={2}>
            {openedLinkedRowDialog && (
              <RowSwitcher
                type={'linked-row'}
                rowId={openedLinkedRowDialog.rowId}
                fieldId={openedLinkedRowDialog.fieldId}
              />
            )}
            <Box pl={2}>
              <Heading fontSize={'lg'} fontWeight={'semibold'}>
                {openedLinkedRowDialog?.title}
              </Heading>
              <Text fontSize={'sm'} color={'GrayText'}>
                <Trans>The following rows where recommended</Trans>
              </Text>
            </Box>
            <Dialog.CloseTrigger asChild>
              <CloseButton onClick={onClose} mt={-2} mr={-2} size={'sm'} ml={'auto'} />
            </Dialog.CloseTrigger>
          </Dialog.Header>
          <Dialog.Body pl={0}>
            {openedLinkedRowDialog && (
              <LinkedRowBodyWrapper openedLinkedRowDialog={openedLinkedRowDialog} />
            )}
          </Dialog.Body>
        </Dialog.Content>
      </Dialog.Positioner>
    </Dialog.Root>
  );
};

const LinkedRowBodyWrapper = ({
  openedLinkedRowDialog
}: {
  openedLinkedRowDialog: OpenedLinkedRowDialog;
}) => {
  const { updateRow, getRowById } = useData();
  const { getFieldById } = useFieldStore();

  const linkedRowData: TableRowLinkedRowType | null = React.useMemo(() => {
    const row = getRowById(openedLinkedRowDialog.rowId);
    const value = row[openedLinkedRowDialog.fieldId] ?? null;
    const typedValue = isTableRowLinkedRowTypeArray(value) ? value : null;
    if (typedValue === null) {
      return null;
    }
    return typedValue;
  }, [openedLinkedRowDialog, getRowById]);

  if (linkedRowData === null) {
    return null;
  }

  return (
    <LinkedRowBody
      fieldId={openedLinkedRowDialog.fieldId}
      rowId={openedLinkedRowDialog.rowId}
      linkedRowData={linkedRowData}
      linkedTableId={getFieldById(openedLinkedRowDialog.fieldId)?.linkedRowConfig!.tableId}
      updateLinkedRows={linkedRows =>
        updateRow({
          id: openedLinkedRowDialog.rowId,
          [openedLinkedRowDialog.fieldId]: linkedRows
        })
      }
      type={openedLinkedRowDialog.linkedRowConfigType}
    />
  );
};
