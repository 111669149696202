'use client';

import { AgGridReact } from '@ag-grid-community/react';
import {
  addProposedChangesToTableRows,
  convertDtoToUpsertTableRow,
  filterTableRows,
  isTableRowLinkedRowTypeArray
} from '@company/common/lib';
import {
  removeNullish,
  TableRowDataType,
  TableRowDto,
  UpsertTableRow,
  UpsertTableRowDataType
} from '@company/common/types';
import type { ProposedChange } from '@company/database/core';
import { toaster } from '@company/ui/components';
import React from 'react';
import { typeidUnboxed } from 'typeid-js';
import {
  approveChangeProposalAction,
  declineChangeProposalAction,
  modifyTableRowsAction
} from '../actions';
import { useSubscribeToProposedChanges } from '../hooks/use-subscribe-to-proposed-changes';
import { useFieldStore } from '../stores/field-store';
import { useTableStore } from '../stores/table-store';
import { useViewStore } from '../stores/view-store';
import { TableRowDtoUi } from '../types';
import { getRowIdMaps, getRowsWithPath, isLeafRow as isLeafRowHelper } from '../utils';

type AddTableRow = {
  data: Record<string, TableRowDataType>;
};

type DeleteTableRow = {
  id: string;
};

type UpdateTableRow = Omit<
  TableRowDto,
  'parentRowId' | 'parentTableRowId' | 'proposedChange' | 'primaryFieldValue'
> & {
  id: string;
  parentRowId?: string | null;
  parentTableRowId?: string | null;
};

interface DataContextType {
  gridApiRef: React.Ref<AgGridReact<TableRowDtoUi>> | undefined;
  rows: TableRowDtoUi[];
  getNonLeafRowIds: () => string[];
  isLeafRow: (rowId: string) => boolean;
  addRow: (row: AddTableRow) => void;
  updateRow: (row: UpdateTableRow) => void;
  deleteRow: (row: DeleteTableRow) => void;
  deleteRows: (rows: DeleteTableRow[]) => void;
  getRowById: (rowId: string) => TableRowDtoUi;
  approveRemainingProposedChanges: () => Promise<void>;
  declineRemainingProposedChanges: () => Promise<void>;
  clearCellValues: (cells: { rowId: string; fieldId: string }[]) => void;
  getPreviousLeafRow: (rowId: string) => TableRowDtoUi | null;
  getNextLeafRow: (rowId: string) => TableRowDtoUi | null;
  getValue: (rowId: string, fieldId: string) => TableRowDataType | null;
}

const DataContext = React.createContext<DataContextType>({} as DataContextType);

interface DataProviderProps {
  children: React.ReactNode;
  gridApiRef: React.RefObject<AgGridReact<TableRowDtoUi>>;
}

export const DataProvider = ({ children, gridApiRef }: DataProviderProps) => {
  const { table, updateTable } = useTableStore();
  const { getFieldById } = useFieldStore();
  const { activeView, isViewingChangeProposalDiff, hideChangeProposalDiff } = useViewStore();

  const handleAddProposedChangesToTableRows = React.useCallback(
    (proposedChanges: ProposedChange[]) => {
      if (proposedChanges.length === 0) {
        return;
      }
      updateTable(table => {
        return {
          ...table,
          rows: addProposedChangesToTableRows({ rows: table.rows, proposedChanges })
        };
      });
    },
    [updateTable]
  );

  useSubscribeToProposedChanges({
    changeProposalId: table.changeProposalId,
    addProposedChangesToTableRows: handleAddProposedChangesToTableRows
  });

  const { rowIdToParentRowId, rowIdToChildRowIds } = React.useMemo(
    () => getRowIdMaps({ rows: table.rows }),
    [table.rows]
  );

  const isLeafRow = React.useCallback(
    (rowId: string) => isLeafRowHelper(rowIdToChildRowIds, rowId),
    [rowIdToChildRowIds]
  );

  const getNonLeafRowIds = React.useCallback(() => {
    return table.rows.filter(row => !isLeafRow(row.id)).map(row => row.id);
  }, [table.rows, isLeafRow]);

  const rowsWithPath = React.useMemo((): TableRowDtoUi[] => {
    const rows = getRowsWithPath({
      rows: table.rows,
      rowIdToParentRowId
    });

    return filterTableRows({ rows, filters: activeView.state.filters }).filter(
      row => !isViewingChangeProposalDiff || row.proposedChange !== null || !isLeafRow(row.id)
    );
  }, [table.rows, rowIdToParentRowId, activeView.state.filters, isViewingChangeProposalDiff]);

  const rowIdToRow = React.useMemo(() => {
    return rowsWithPath.reduce(
      (acc, row) => {
        acc[row.id] = row;
        return acc;
      },
      {} as Record<string, TableRowDtoUi>
    );
  }, [rowsWithPath]);

  const getRowById = React.useCallback(
    (rowId: string) => {
      return rowIdToRow[rowId]!;
    },
    [rowIdToRow]
  );

  const addRow = React.useCallback(() => {
    const newRow: TableRowDto = {
      id: typeidUnboxed('row'),
      parentRowId: null,
      parentTableRowId: table.parentTableId,
      primaryFieldValue: null,
      proposedChange: null
    };
    updateTable(table => ({
      ...table,
      rows: [...table.rows, newRow]
    }));
    return newRow;
  }, [updateTable]);

  const updateRow = React.useCallback(
    (updatedRow: UpdateTableRow) => {
      const currentRow = table.rows.find(row => row.id === updatedRow.id);
      if (!currentRow) {
        return;
      }

      const rowNode = gridApiRef.current?.api.getRowNode(updatedRow.id);
      if (rowNode?.data) {
        gridApiRef.current?.api.applyTransaction({
          update: [{ ...rowNode.data, ...updatedRow }]
        });
      }

      updateTable(table => ({
        ...table,
        rows: table.rows.map(row => (row.id === updatedRow.id ? { ...row, ...updatedRow } : row))
      }));
      void modifyTableRowsAction({
        tableId: table.id,
        toUpsertRows: [convertDtoToUpsertTableRow(updatedRow)],
        toDeleteRows: []
      });
    },
    [updateTable, gridApiRef]
  );

  const clearCellValues = React.useCallback(
    (cells: { rowId: string; fieldId: string }[]) => {
      updateTable(table => ({
        ...table,
        rows: table.rows.map(row => {
          if (!cells.some(cell => cell.rowId === row.id)) {
            return row;
          }
          const updatedRow = { ...row };
          cells.forEach(cell => {
            if (cell.rowId === row.id) {
              updatedRow[cell.fieldId] = null;
            }
          });
          return updatedRow;
        })
      }));

      const rowIdToUpsertTableRow = cells.reduce(
        (acc, cell) => {
          const field = getFieldById(cell.fieldId);
          const row = rowIdToRow[cell.rowId]!;
          const cellValue = row[cell.fieldId]!;

          const value: UpsertTableRowDataType =
            field?.type === 'LINKED_ROW' && isTableRowLinkedRowTypeArray(cellValue)
              ? {
                  linkedRows: []
                }
              : null;

          if (!acc[cell.rowId]) {
            acc[cell.rowId] = {
              id: cell.rowId,
              [cell.fieldId]: value
            };
          } else {
            acc[cell.rowId]![cell.fieldId] = value;
          }

          return acc;
        },
        {} as Record<string, UpsertTableRow>
      );

      void modifyTableRowsAction({
        tableId: table.id,
        toUpsertRows: Object.values(rowIdToUpsertTableRow),
        toDeleteRows: []
      });
    },
    [updateTable, rowIdToRow]
  );

  const deleteRow = React.useCallback(
    (row: DeleteTableRow) => {
      updateTable(table => ({
        ...table,
        rows: table.rows.filter(r => row.id !== r.id)
      }));
      void modifyTableRowsAction({
        tableId: table.id,
        toUpsertRows: [],
        toDeleteRows: [row]
      });
    },
    [updateTable]
  );

  const deleteRows = React.useCallback(
    (rows: DeleteTableRow[]) => {
      updateTable(table => ({
        ...table,
        rows: table.rows.filter(r => !rows.some(row => row.id === r.id))
      }));
      void modifyTableRowsAction({
        tableId: table.id,
        toUpsertRows: [],
        toDeleteRows: rows
      });
    },
    [updateTable]
  );

  const approveRemainingProposedChanges = React.useCallback(async () => {
    if (!table.changeProposalId) {
      return;
    }

    const rowsWithProposedChanges = table.rows.filter(row => row.proposedChange !== null);
    await approveChangeProposalAction({
      tableId: table.id,
      changeProposalId: table.changeProposalId,
      toUpsertRows: rowsWithProposedChanges
        .filter(
          row => row.proposedChange?.type === 'INSERT' || row.proposedChange?.type === 'UPDATE'
        )
        .map(convertDtoToUpsertTableRow),
      toDeleteRows: rowsWithProposedChanges
        .filter(row => row.proposedChange?.type === 'DELETE')
        .map(row => ({ id: row.id }))
    });
    updateTable(table => ({
      ...table,
      changeProposalId: null,
      rows: table.rows.map(row => ({
        ...row,
        proposedChange: null
      }))
    }));
    hideChangeProposalDiff();
    toaster.create({
      title: 'Changes Approved',
      description: 'Changes have been approved',
      type: 'success'
    });
  }, [table, updateTable]);

  const declineRemainingProposedChanges = React.useCallback(async () => {
    if (!table.changeProposalId) {
      return;
    }
    await declineChangeProposalAction({
      tableId: table.id,
      changeProposalId: table.changeProposalId
    });
    updateTable(table => ({
      ...table,
      changeProposalId: null,
      rows: table.rows
        .map(row => {
          if (row.proposedChange) {
            if (row.proposedChange.type === 'INSERT') {
              return null;
            } else if (row.proposedChange.type === 'UPDATE') {
              const oldValue = row.proposedChange.oldValue;
              return { ...row, proposedChange: null, ...oldValue };
            } else if (row.proposedChange.type === 'DELETE') {
              return { ...row, proposedChange: null };
            }
          }
          return row;
        })
        .filter(removeNullish)
    }));
    hideChangeProposalDiff();
    toaster.create({
      title: 'Changes Declined',
      description: 'Changes have been declined',
      type: 'error'
    });
  }, [table, updateTable]);

  const getPreviousLeafRow = React.useCallback(
    (rowId: string) => {
      const index = rowsWithPath.findIndex(row => row.id === rowId);
      const row = rowsWithPath[index - 1] ?? null;
      if (row && !isLeafRow(row.id)) {
        return getPreviousLeafRow(row.id);
      }
      return row;
    },
    [rowsWithPath, isLeafRow]
  );

  const getNextLeafRow = React.useCallback(
    (rowId: string) => {
      const index = rowsWithPath.findIndex(row => row.id === rowId);
      const row = rowsWithPath[index + 1] ?? null;
      if (row && !isLeafRow(row.id)) {
        return getNextLeafRow(row.id);
      }
      return row;
    },
    [rowsWithPath, isLeafRow]
  );

  const getValue = React.useCallback(
    (rowId: string, fieldId: string) => {
      const row = getRowById(rowId);
      return row[fieldId] ?? null;
    },
    [getRowById]
  );

  const value = React.useMemo(
    () => ({
      rows: rowsWithPath,
      addRow,
      updateRow,
      deleteRow,
      deleteRows,
      getRowById,
      isLeafRow,
      getNonLeafRowIds,
      approveRemainingProposedChanges,
      declineRemainingProposedChanges,
      clearCellValues,
      getPreviousLeafRow,
      getNextLeafRow,
      getValue,
      gridApiRef
    }),
    [
      rowsWithPath,
      updateRow,
      deleteRow,
      deleteRows,
      addRow,
      isLeafRow,
      getNonLeafRowIds,
      approveRemainingProposedChanges,
      declineRemainingProposedChanges,
      clearCellValues,
      getPreviousLeafRow,
      getNextLeafRow,
      getValue,
      gridApiRef
    ]
  );

  return <DataContext.Provider value={value}>{children}</DataContext.Provider>;
};

export const useData = () => {
  const context = React.useContext(DataContext);
  if (!context) {
    throw new Error('useData must be used within a DataProvider');
  }
  return context;
};
