'use client';

import { logger } from '@company/common/logger';
import type { ProposedChange } from '@company/database/core';
import { createSupabaseBrowserClient } from '@company/supabase/client/browser';
import { getProposedChangesRealtimeChannelName } from '@company/supabase/realtime';
import { useDebouncedBatchProcessing } from '@company/ui/hooks';
import React from 'react';

interface UseSubscribeToProposedChangesProps {
  changeProposalId: string | null;
  addProposedChangesToTableRows: (proposedChanges: ProposedChange[]) => void;
}

export const useSubscribeToProposedChanges = ({
  changeProposalId,
  addProposedChangesToTableRows
}: UseSubscribeToProposedChangesProps) => {
  const { addToProcessingList } = useDebouncedBatchProcessing<ProposedChange>({
    onProcess: addProposedChangesToTableRows,
    debounceInMs: 1_000
  });

  React.useEffect(() => {
    if (!changeProposalId) {
      return;
    }

    const supabase = createSupabaseBrowserClient();
    const proposedChangesChannel = supabase
      .channel(getProposedChangesRealtimeChannelName(changeProposalId))
      .on<ProposedChange>(
        'postgres_changes',
        {
          event: 'INSERT',
          schema: 'public',
          table: 'ProposedChange',
          filter: `changeProposalId=eq.${changeProposalId}`
        },
        payload => {
          addToProcessingList(payload.new);
        }
      )
      .on<ProposedChange>(
        'postgres_changes',
        {
          event: 'UPDATE',
          schema: 'public',
          table: 'ProposedChange',
          filter: `changeProposalId=eq.${changeProposalId}`
        },
        payload => {
          addToProcessingList(payload.new);
        }
      )
      .subscribe(subscription => {
        logger.info(`Subscribed to proposed changes for ${changeProposalId}: ${subscription}`);
      });

    return () => {
      proposedChangesChannel.unsubscribe();
    };
  }, [changeProposalId]);
};
