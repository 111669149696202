'use client';

import '@ag-grid-community/styles/ag-grid.css';
import '@ag-grid-community/styles/ag-theme-quartz.css';
import './styles.css';

import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import {
  CellEditingStartedEvent,
  CellSelectionDeleteStartEvent,
  ColumnMovedEvent,
  ColumnResizedEvent,
  GetRowIdParams,
  GridOptions,
  ModuleRegistry,
  RowClassParams
} from '@ag-grid-community/core';
import { AgGridReact } from '@ag-grid-community/react';
import { ClipboardModule } from '@ag-grid-enterprise/clipboard';
import { RangeSelectionModule } from '@ag-grid-enterprise/range-selection';
import { RowGroupingModule } from '@ag-grid-enterprise/row-grouping';
import { Box, Stack } from '@company/ui/components';
import { TableRowDtoUi } from '@components/table/types';
import React from 'react';
import { GridActionBar } from './action-bar';
import { ChildTableDialog } from './child-table';
import { LinkedRowDialog } from './linked-row';
import { GridProvider, useGrid } from './provider';
import { QuickFilter } from './quick-filter';

ModuleRegistry.registerModules([
  ClientSideRowModelModule,
  RowGroupingModule,
  RangeSelectionModule,
  ClipboardModule
]);

export const GridView = ({
  config,
  header,
  gridApiRef
}: {
  config?: {};
  header?: React.ReactNode;
  gridApiRef: React.RefObject<AgGridReact<TableRowDtoUi>> | null;
}) => {
  return (
    <GridProvider>
      <Grid header={header} gridApiRef={gridApiRef} />
    </GridProvider>
  );
};

const Grid = ({
  header,
  gridApiRef
}: {
  header?: React.ReactNode;
  gridApiRef: React.RefObject<AgGridReact<TableRowDtoUi>> | null;
}) => {
  const {
    rows,
    columns,
    components,
    openedChildTableDialog,
    openedLinkedRowDialog,
    quickFilterText,
    onCloseChildTableDialog,
    onCloseLinkedRowDialog,
    onColumnResize,
    onColumnMove,
    getRowStyle,
    onClearCellValues,
    shouldCellBePreventedFromEditing
  } = useGrid();

  const onColumnResizedEvent = React.useCallback(
    (event: ColumnResizedEvent<TableRowDtoUi>) => {
      if (event.finished) {
        onColumnResize(event.column!.getColId(), event.column!.getActualWidth());
      }
    },
    [onColumnResize]
  );

  const onColumnMovedEvent = React.useCallback(
    (event: ColumnMovedEvent<TableRowDtoUi>) => {
      if (event.finished && event.toIndex !== undefined) {
        onColumnMove(event.column!.getColId(), event.toIndex);
      }
    },
    [onColumnMove]
  );

  const getRowStyleHandler = React.useCallback(
    (params: RowClassParams<TableRowDtoUi>) => {
      if (!params.data) {
        return;
      }
      return getRowStyle(params.data.id);
    },
    [getRowStyle]
  );

  const onCellSelectionDeleteStart = React.useCallback(
    (event: CellSelectionDeleteStartEvent<TableRowDtoUi>) => {
      const cellRanges = event.api.getCellRanges();
      const cells: { rowId: string; fieldId: string }[] = [];

      cellRanges?.forEach(cellRange => {
        const startRowIndex = cellRange.startRow?.rowIndex;
        const endRowIndex = cellRange.endRow?.rowIndex;
        const columns = cellRange.columns;

        if (startRowIndex == null || endRowIndex == null) return;

        // Ensure the range is inclusive and handles reverse selection
        const [minRowIndex, maxRowIndex] = [
          Math.min(startRowIndex, endRowIndex),
          Math.max(startRowIndex, endRowIndex)
        ];

        for (let i = minRowIndex; i <= maxRowIndex; i++) {
          const rowNode = event.api.getDisplayedRowAtIndex(i);
          if (rowNode) {
            const rowId = rowNode.data?.id;
            if (rowId == null) {
              return;
            }

            columns.forEach(column => {
              const fieldId = column.getColId();
              cells.push({ rowId, fieldId });
            });
          }
        }
      });

      onClearCellValues(cells);
    },
    [onClearCellValues]
  );

  const onCellEditingStarted = React.useCallback(
    (event: CellEditingStartedEvent<TableRowDtoUi>) => {
      if (shouldCellBePreventedFromEditing(event.column.getId())) {
        event.api.stopEditing();
      }
    },
    []
  );

  const getDataPath = React.useCallback((params: TableRowDtoUi) => {
    return params.parentRowIdPath;
  }, []);

  const getRowId = React.useCallback((params: GetRowIdParams<TableRowDtoUi>) => {
    return params.data.id;
  }, []);

  const gridOptions = React.useMemo((): GridOptions<TableRowDtoUi> => {
    return {
      cellSelection: true,
      rowSelection: {
        mode: 'multiRow',
        checkboxes: false,
        headerCheckbox: false,
        enableClickSelection: false,
        copySelectedRows: true,
        enableSelectionWithoutKeys: false
      }
    };
  }, []);

  return (
    <>
      <Box width="100%" height="100%">
        <Stack gap={0} height="100%" width="100%" position="relative">
          {header}
          <Box
            className="ag-theme-quartz"
            width="100%"
            height="100%"
            flexGrow={1}
            flexShrink={1}
            flexBasis={0}
            position={'relative'}
          >
            <AgGridReact
              ref={gridApiRef}
              rowData={rows}
              columnDefs={columns}
              components={components}
              onColumnResized={onColumnResizedEvent}
              onColumnMoved={onColumnMovedEvent}
              getRowStyle={getRowStyleHandler}
              treeData={true}
              treeDataDisplayType="custom"
              groupDefaultExpanded={-1}
              getDataPath={getDataPath}
              getRowId={getRowId}
              quickFilterText={quickFilterText}
              gridOptions={gridOptions}
              onCellSelectionDeleteStart={onCellSelectionDeleteStart}
              onCellEditingStarted={onCellEditingStarted}
              rowBuffer={25}
            />
            <QuickFilter />
            <ChildTableDialog
              openedChildTableDialog={openedChildTableDialog}
              onClose={onCloseChildTableDialog}
            />
            <LinkedRowDialog
              openedLinkedRowDialog={openedLinkedRowDialog}
              onClose={onCloseLinkedRowDialog}
            />
            <GridActionBar gridApiRef={gridApiRef} />
          </Box>
        </Stack>
      </Box>
    </>
  );
};
