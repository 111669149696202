import { I18n } from '@lingui/core';
import { typeidUnboxed } from 'typeid-js';
import {
  COST_CALCULATION_TABLE_FIELDS,
  CreateTable,
  UpsertTableRow,
  WORK_ITEM_TABLE_FIELDS
} from '../../../types/';

export const getCreateTableConfigForITwoProject = (
  {
    boqTable,
    costCalculationTable,
    projectId,
    viewConfig
  }: {
    boqTable: {
      tableName: string;
      rows: UpsertTableRow[];
      fieldReferenceIdToId?: Record<string, string>;
    };
    costCalculationTable: {
      rows: UpsertTableRow[];
      fieldReferenceIdToId?: Record<string, string>;
    };
    projectId: string | null;
    viewConfig?: {
      canDeleteRows: boolean;
    };
  },
  i18n: I18n
): CreateTable => {
  const boqTableFieldReferenceIdToId =
    boqTable.fieldReferenceIdToId ??
    Object.fromEntries(
      Object.values(WORK_ITEM_TABLE_FIELDS).map(field => [field.id, typeidUnboxed('field')])
    );
  const costCalculationTableFieldReferenceIdToId =
    costCalculationTable.fieldReferenceIdToId ??
    Object.fromEntries(
      Object.values(COST_CALCULATION_TABLE_FIELDS).map(field => [field.id, typeidUnboxed('field')])
    );

  return {
    name: boqTable.tableName,
    rowTitleTemplate: `{{${boqTableFieldReferenceIdToId[WORK_ITEM_TABLE_FIELDS.referenceNumber.id]}}} {{${boqTableFieldReferenceIdToId[WORK_ITEM_TABLE_FIELDS.shortText.id]}}}`,
    dataType: 'I_TWO_BOQ',
    projectId,
    fields: Object.values(WORK_ITEM_TABLE_FIELDS).map(field => ({
      id: boqTableFieldReferenceIdToId[field.id],
      referenceId: field.id,
      name: field.getLabel(i18n.t),
      type: field.type,
      isEditable: field.isEditable,
      isPrimary: field.isPrimary,
      isRagSearchable: field.isRagSearchable,
      linkedRowConfig: null,
      selectConfig: field.selectOptions
        ? {
            options: field.selectOptions
          }
        : null,
      numberConfig: field.numberConfig ?? null
    })),
    rows: boqTable.rows,
    views: [
      {
        name: 'WBS',
        type: 'GRID',
        config: {
          canDeleteRows: viewConfig?.canDeleteRows ?? false
        },
        state: {
          type: 'GRID',
          filters: [],
          fields: Object.values(WORK_ITEM_TABLE_FIELDS)
            .filter(field => field.isVisible)
            .map((field, index) => ({
              id: boqTableFieldReferenceIdToId[field.id]!,
              width: field.defaultWidth,
              isHidden: !field.isVisible
            }))
        }
      }
    ],
    childTable: {
      id: typeidUnboxed('ctbl'),
      projectId,
      dataType: 'I_TWO_COST_CALCULATION',
      name: i18n._('Cost Calculation'),
      rowTitleTemplate: null,
      fields: Object.values(COST_CALCULATION_TABLE_FIELDS).map(field => ({
        id: costCalculationTableFieldReferenceIdToId[field.id]!,
        referenceId: field.id,
        name: field.getLabel(i18n.t),
        type: field.type,
        isEditable: field.isEditable,
        isPrimary: field.isPrimary,
        isRagSearchable: field.isRagSearchable,
        linkedRowConfig: null,
        selectConfig: field.selectOptions
          ? {
              options: field.selectOptions
            }
          : null,
        numberConfig: field.numberConfig ?? null
      })),
      rows: costCalculationTable.rows,
      views: [
        {
          name: 'WBS',
          type: 'GRID',
          config: {
            canDeleteRows: false
          },
          state: {
            type: 'GRID',
            filters: [],
            fields: Object.values(COST_CALCULATION_TABLE_FIELDS)
              .filter(field => field.isVisible)
              .map((field, index) => ({
                id: costCalculationTableFieldReferenceIdToId[field.id]!,
                width: field.defaultWidth,
                isHidden: !field.isVisible
              }))
          }
        }
      ],
      childTable: null
    }
  };
};
