'use client';

import React from 'react';
import { useSidebar } from './sidebar-provider';

interface PageContainerContextProps {
  isFullScreen: boolean;
  goFullScreen: () => void;
  exitFullScreen: () => void;
}

const PageContainerContext = React.createContext<PageContainerContextProps>({
  isFullScreen: false,
  goFullScreen: () => {},
  exitFullScreen: () => {}
});

interface PageContainerProviderProps {
  children: React.ReactNode;
}

export const usePageContainer = () => React.useContext(PageContainerContext);

export const PageContainerProvider = ({ children }: PageContainerProviderProps) => {
  const { hide, expand } = useSidebar();
  const [isFullScreen, setIsFullScreen] = React.useState(false);

  const goFullScreen = () => {
    setIsFullScreen(true);
    hide();
  };
  const exitFullScreen = () => {
    setIsFullScreen(false);
    expand();
  };

  const value = React.useMemo(
    () => ({
      isFullScreen,
      goFullScreen,
      exitFullScreen
    }),
    [isFullScreen, goFullScreen, exitFullScreen]
  );

  return <PageContainerContext.Provider value={value}>{children}</PageContainerContext.Provider>;
};
