import { msg } from '@lingui/core/macro';
import { z } from 'zod';
import { EnumMap } from '../utils';

export const platformLanguageTypeSchema = z.enum(['EN', 'DE']);
export type PlatformLanguageType = z.infer<typeof platformLanguageTypeSchema>;

export const localeCodeSchema = z.enum(['en', 'de']);
export type LocaleCodeType = z.infer<typeof localeCodeSchema>;

export const PLATFORM_LANGUAGES: EnumMap<
  PlatformLanguageType,
  {
    localeCode: LocaleCodeType;
    locale: Intl.LocalesArgument;
  }
> = {
  EN: { id: 'EN', getLabel: t => t(msg`English`), localeCode: 'en', locale: 'en-US' },
  DE: { id: 'DE', getLabel: t => t(msg`German`), localeCode: 'de', locale: 'de-DE' }
};
