'use client';

import { pickAvatarColor } from '@company/common/types';
import { Avatar, AvatarRootProps } from '@company/ui/components';

interface UserAvatarProps {
  name?: string;
  firstName?: string;
  lastName?: string;
  size: 'lg' | 'md' | 'sm' | 'xs' | 'x-small' | 'xx-small';
  rounded?: AvatarRootProps['rounded'];
}

export function UserAvatar({
  name,
  firstName,
  lastName,
  size = 'xs',
  rounded = 'lg'
}: UserAvatarProps) {
  if (firstName && lastName) {
    return (
      <Avatar
        size={size}
        shape={'rounded'}
        colorPalette={pickAvatarColor(firstName)}
        fallback={`${firstName[0]}${lastName[0]}`}
        rounded={rounded}
      />
    );
  } else if (name) {
    return (
      <Avatar size={size} shape={'rounded'} fallback={name} colorPalette={''} rounded={rounded} />
    );
  }

  return null;
}
