'use client';

import { Box, Button, Center, Flex, IconButton, Tag, Text } from '@company/ui/components';
import { BrainIcon, CheckCircleIcon, TrashIcon } from '@company/ui/icons';
import { Trans } from '@lingui/react/macro';
import React from 'react';
import { LinkedRowTag } from './tag';

interface LinkedRowRecommendationProps {
  name: string;
  score: number | null;
  isSelected: boolean;
  isOpen: boolean;
  isFromAiMemory: boolean;
  onSelect: () => void;
  onUnselect: () => void;
  onRemove: () => void;
  onClick: () => void;
}

export const LinkedRowRecommendationSelector = ({
  name,
  score,
  isSelected,
  isOpen,
  isFromAiMemory,
  onSelect,
  onUnselect,
  onRemove,
  onClick
}: LinkedRowRecommendationProps) => {
  const [isHovering, setIsHovering] = React.useState(false);
  return (
    <Box
      py={2}
      px={4}
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
      bgColor={
        isSelected ? 'green.50' : isOpen ? 'blue.50' : isHovering ? 'gray.50' : 'transparent'
      }
      cursor={'pointer'}
      onClick={onClick}
      borderLeftWidth={4}
      borderLeftColor={isSelected ? 'green.500' : isOpen ? 'blue.500' : 'transparent'}
      userSelect={'none'}
    >
      <Box mb={1}>
        <Flex justify={'space-between'} minH={'32px'}>
          <Box>
            <Text fontSize={'sm'} lineClamp={2} lineHeight={1.35}>
              {name}
            </Text>
          </Box>
          <Center w={'32px'} h={'32px'} mt={-1}>
            {isSelected ? (
              <CheckCircleIcon color="green.500" boxSize={5} />
            ) : (
              <IconButton
                variant="ghost"
                onClick={e => {
                  e.stopPropagation();
                  onRemove();
                }}
                size="sm"
              >
                <TrashIcon />
              </IconButton>
            )}
          </Center>
        </Flex>
      </Box>
      <Flex justify={'space-between'} h={'28px'} mt={2}>
        <Box>
          {isOpen ? (
            <Button
              colorPalette={isSelected ? 'red' : 'blue'}
              variant={'surface'}
              size="xs"
              h={'28px'}
              onClick={e => {
                e.stopPropagation();
                if (isSelected) {
                  onUnselect();
                } else {
                  onSelect();
                }
              }}
            >
              {isSelected ? <Trans>Unselect</Trans> : <Trans>Select</Trans>}
            </Button>
          ) : (
            isFromAiMemory && (
              <Flex alignItems={'center'} gap={1} fontSize={'xs'} color={'purple.400'}>
                <BrainIcon boxSize={3.5} />
                <Trans>Remembered by AI-Agent</Trans>
              </Flex>
            )
          )}
        </Box>
        <Box>
          <LinkedRowTag score={score} />
        </Box>
      </Flex>
    </Box>
  );
};
