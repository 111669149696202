'use client';

import { uuid } from '@company/common/lib';
import {
  AddFieldToTable,
  DeleteField,
  EditFieldInTable,
  FieldDto,
  MakeFieldPrimary,
  TableDto
} from '@company/common/types';
import { createZustandContext } from '@stores/create';
import { typeidUnboxed } from 'typeid-js';
import { create } from 'zustand';
import {
  createFieldAction,
  deleteFieldAction,
  editFieldAction,
  makeFieldPrimaryAction
} from '../actions/field';

interface InitialState {
  table: TableDto;
  updateTable: (table: TableDto | ((prev: TableDto) => TableDto)) => void;
}

interface FieldStore {
  addField: (field: AddFieldToTable) => void;
  editField: (field: EditFieldInTable) => void;
  deleteField: (field: DeleteField) => void;
  makeFieldPrimary: (field: MakeFieldPrimary) => void;
  getFieldById: (fieldId: string) => FieldDto;
  getPrimaryField: () => FieldDto;
}

export const [FieldStoreProvider, useFieldStore] = createZustandContext<InitialState, FieldStore>(
  ({ table, updateTable }) =>
    create<FieldStore>(() => ({
      addField: async ({ name, type, linkedRowConfig }: AddFieldToTable) => {
        const newField: FieldDto = {
          id: typeidUnboxed('field'),
          referenceId: uuid(),
          name,
          type,
          isEditable: true,
          isPrimary: false,
          linkedRowConfig,
          selectConfig: null,
          numberConfig: null
        };
        updateTable(table => ({
          ...table,
          fields: [...table.fields, newField]
        }));
        void createFieldAction({
          ...newField,
          tableId: table.id
        });
      },

      editField: async (updatedFieldData: EditFieldInTable) => {
        const updatedFields = table.fields.map(field =>
          field.id === updatedFieldData.id ? { ...field, ...updatedFieldData } : field
        );
        updateTable({
          ...table,
          fields: updatedFields
        });
        await editFieldAction(updatedFieldData);
      },

      deleteField: async (field: DeleteField) => {
        const updatedFields = table.fields.filter(f => f.id !== field.id);
        updateTable(table => ({
          ...table,
          fields: updatedFields
        }));
        await deleteFieldAction(field);
      },

      makeFieldPrimary: async (field: MakeFieldPrimary) => {
        const updatedFields = table.fields.map(f =>
          f.id === field.id ? { ...f, isPrimary: true } : { ...f, isPrimary: false }
        );
        updateTable(table => ({
          ...table,
          fields: updatedFields
        }));
        await makeFieldPrimaryAction({
          tableId: table.id,
          fieldId: field.id
        });
      },

      getFieldById: (fieldId: string) => {
        return table.fields.find(field => field.id === fieldId)!;
      },

      getPrimaryField: () => {
        return table.fields.find(field => field.isPrimary)!;
      }
    }))
);
