'use client';

import { Box, Button, Flex, Text } from '@company/ui/components';
import { CheckIcon, DiffIcon, FilterXIcon, XIcon } from '@company/ui/icons';
import { useLingui } from '@lingui/react';
import { Trans } from '@lingui/react/macro';
import React from 'react';
import { useData } from '../providers/data-provider';
import { useTableStore } from '../stores/table-store';
import { useViewStore } from '../stores/view-store';
import { TableHeaderProps } from '../types';
import { ExportTableButton } from './export-table-button';
import { ShareButton } from './share-button';

interface ITableHeaderProps {
  header?: TableHeaderProps;
}

export const TableHeader = ({ header }: ITableHeaderProps) => {
  const { table } = useTableStore();
  const { _ } = useLingui();

  return (
    <Box w={'full'} h={'48px'} px={4} bgColor={'white'}>
      <Flex h={'full'} alignItems="center" gap={4} justify={'space-between'}>
        <Box h={'full'}>
          <Flex h={'full'} alignItems={'center'} gap={1.5}>
            {header?.leftAddon}
            <Text fontSize={'sm'} fontWeight={'medium'}>
              {table.name}
            </Text>
          </Flex>
        </Box>
        <Box h={'full'}>
          <Flex h={'full'} alignItems={'center'} gap={1.5}>
            {table.changeProposalId !== null && <ChangeProposalActions />}
            <ShareButton />
            <ExportTableButton table={table} />
            {header?.rightAddon}
          </Flex>
        </Box>
      </Flex>
    </Box>
  );
};

const ChangeProposalActions = () => {
  const [isApprovingChangeProposal, setIsApprovingChangeProposal] = React.useState(false);
  const [isDecliningChangeProposal, setIsDecliningChangeProposal] = React.useState(false);

  const { isViewingChangeProposalDiff, hideChangeProposalDiff, viewChangeProposalDiff } = useViewStore();
  const { approveRemainingProposedChanges, declineRemainingProposedChanges } = useData();

  const onViewOnlyProposedChangeToggle = React.useCallback(() => {
    if (isViewingChangeProposalDiff) {
      hideChangeProposalDiff();
    } else {
      viewChangeProposalDiff();
    }
  }, [isViewingChangeProposalDiff, hideChangeProposalDiff, viewChangeProposalDiff]);

  const onApproveChangeProposal = async () => {
    setIsApprovingChangeProposal(true);
    await approveRemainingProposedChanges();
    setIsApprovingChangeProposal(false);
  };

  const onDeclineChangeProposal = async () => {
    setIsDecliningChangeProposal(true);
    await declineRemainingProposedChanges();
    setIsDecliningChangeProposal(false);
  };

  return (
    <>
      <Button onClick={onViewOnlyProposedChangeToggle} size={'sm'} variant={'subtle'}>
        {isViewingChangeProposalDiff ? <FilterXIcon /> : <DiffIcon />}
        {isViewingChangeProposalDiff ? (
          <Trans>Show All</Trans>
        ) : (
          <Trans>Proposed Changes Only</Trans>
        )}
      </Button>
      <Button
        loading={isApprovingChangeProposal}
        loadingText="Approving"
        disabled={isDecliningChangeProposal}
        onClick={onApproveChangeProposal}
        colorPalette={'green'}
        size={'sm'}
      >
        <CheckIcon />
        <Trans>Approve Changes</Trans>
      </Button>
      <Button
        loading={isDecliningChangeProposal}
        loadingText="Declining"
        disabled={isApprovingChangeProposal}
        onClick={onDeclineChangeProposal}
        colorPalette={'red'}
        size={'sm'}
      >
        <XIcon />
        <Trans>Decline Changes</Trans>
      </Button>
    </>
  );
};
