'use client';

import { TableDto } from '@company/common/types';
import { createZustandContext } from '@stores/create';
import { create } from 'zustand';

interface InitialState {
  table: TableDto;
}

interface TableStore extends InitialState {
  updateTable: (table: TableDto | ((prev: TableDto) => TableDto)) => void;
}

export const [TableStoreProvider, useTableStore] = createZustandContext<InitialState, TableStore>(
  initial =>
    create<TableStore>((set, get) => ({
      table: initial.table,
      updateTable: (table: TableDto | ((prev: TableDto) => TableDto)) => {
        const newTable = table instanceof Function ? table(get().table) : table;
        set({ table: newTable });
      }
    }))
);
