'use client';

import { PieceOfInformationDto } from '@company/common/types';
import { trpc } from '@server/trpc';
import React from 'react';

interface WorkspaceContextProps {
  recentlyUsedPiecesOfInformation: PieceOfInformationDto[];
  addRecentlyUsedPieceOfInformation: (pieceOfInformation: PieceOfInformationDto) => void;
  refreshRecentlyUsedPiecesOfInformation: () => void;
}

const WorkspaceContext = React.createContext<WorkspaceContextProps>({
  recentlyUsedPiecesOfInformation: [],
  addRecentlyUsedPieceOfInformation: () => {},
  refreshRecentlyUsedPiecesOfInformation: () => {}
});
export const useWorkspace = () => React.useContext(WorkspaceContext);

export const RecentPieceOfInformationProvider = ({ children }: { children: React.ReactNode }) => {
  const trpcUtils = trpc.useUtils();
  const query = trpcUtils.pieceOfInformation.getRecentlyUsed;

  const { data } = trpc.pieceOfInformation.getRecentlyUsed.useQuery();

  const recentlyUsedPiecesOfInformation = React.useMemo(() => data ?? [], [data]);

  const addRecentlyUsedPieceOfInformation = (pieceOfInformation: PieceOfInformationDto) => {
    const newRecentlyUsedPiecesOfInformation = [
      pieceOfInformation,
      ...recentlyUsedPiecesOfInformation.filter(poi => poi.id !== pieceOfInformation.id)
    ].slice(0, 10);

    query.cancel();
    query.setData(undefined, newRecentlyUsedPiecesOfInformation);
  };

  const refreshRecentlyUsedPiecesOfInformation = async () => {
    query.cancel();
    query.setData(undefined, undefined);
  };

  const value = React.useMemo(
    () => ({
      recentlyUsedPiecesOfInformation,
      addRecentlyUsedPieceOfInformation,
      refreshRecentlyUsedPiecesOfInformation
    }),
    [recentlyUsedPiecesOfInformation]
  );

  return <WorkspaceContext.Provider value={value}>{children}</WorkspaceContext.Provider>;
};
