import type { BoxProps } from '@chakra-ui/react';
import { Group, InputElement } from '@chakra-ui/react';
import React from 'react';

export interface FlexibleInputGroupProps extends BoxProps {
  topElement?: React.ReactNode;
  topElementProps?: BoxProps;
  bottomElement?: React.ReactNode;
  bottomElementProps?: BoxProps;
  startElement?: React.ReactNode;
  startElementProps?: BoxProps;
  endElement?: React.ReactNode;
  endElementProps?: BoxProps;
  elementSpacing?: string;
  children: React.ReactElement;
}

export const InputGroup = React.forwardRef<HTMLDivElement, FlexibleInputGroupProps>(
  function FlexibleInputGroup(props, ref) {
    const {
      topElement,
      topElementProps,
      bottomElement,
      bottomElementProps,
      startElement,
      startElementProps,
      endElement,
      endElementProps,
      elementSpacing = '0.25rem',
      children,
      ...rest
    } = props;

    const calculateHeight = (element: React.ReactNode, height: any) => {
      return element ? `calc(${height} + 0.25rem)` : '0.25rem';
    };

    const calculateWidth = (element: React.ReactNode, width: any) => {
      return element ? `calc(${width} + 0.25rem)` : '0.25rem';
    };

    const topElementHeight = React.useMemo(
      () => calculateHeight(topElement, topElementProps?.height || '2rem'),
      [topElement, topElementProps?.height]
    );

    const startElementWidth = React.useMemo(
      () => calculateWidth(startElement, startElementProps?.width || '2rem'),
      [startElement, startElementProps?.width]
    );

    const endElementWidth = React.useMemo(
      () => calculateWidth(endElement, endElementProps?.width || '2rem'),
      [endElement, endElementProps?.width]
    );

    const bottomElementHeight = React.useMemo(
      () => calculateHeight(bottomElement, bottomElementProps?.height || '2rem'),
      [bottomElement, bottomElementProps?.height]
    );

    return (
      <Group ref={ref} direction="column" {...rest}>
        {topElement && (
          <InputElement w="full" mb={elementSpacing} {...topElementProps}>
            {topElement}
          </InputElement>
        )}
        <Group direction="row" w="full" flex="1">
          {startElement && (
            <InputElement me={elementSpacing} {...startElementProps}>
              {startElement}
            </InputElement>
          )}
          {React.cloneElement(children, {
            ...(topElement ? { paddingTop: topElementHeight } : {}),
            ...(bottomElement ? { paddingBottom: bottomElementHeight } : {}),
            ...(startElement ? { paddingLeft: startElementWidth } : {}),
            ...(endElement ? { paddingRight: endElementWidth } : {}),
            ...(children.props as React.HTMLAttributes<HTMLDivElement>)
          })}
          {endElement && (
            <InputElement placement="end" ms={elementSpacing} {...endElementProps}>
              {endElement}
            </InputElement>
          )}
        </Group>
        {bottomElement && (
          <InputElement
            alignItems={'flex-end'}
            w="full"
            my={elementSpacing}
            px={0}
            {...bottomElementProps}
          >
            {bottomElement}
          </InputElement>
        )}
      </Group>
    );
  }
);
