import { z } from 'zod';
import { agentInformationRequestFieldWithValueSchema } from '../agent';
import { pieceOfInformationDtoSchema } from '../piece-of-information';

export const conversationNodeElementTypeSchema = z.enum([
  'TEXT',
  'ARTIFACT',
  'TASK_RUN_STEP_APPROVAL_BUTTON',
  'FORM',
  'ACTION'
]);

export const conversationNodeEventElementTypeSchema = z.enum([
  'SUCCESS',
  'INFO',
  'WARNING',
  'ERROR'
]);

export const baseConversationNodeElementSchema = z.object({
  id: z.string(),
  createdOn: z.string()
});

export const conversationNodeArtifactSchema = z.object({
  pieceOfInformation: pieceOfInformationDtoSchema,
  viewId: z.string().nullable(),
  changeProposalId: z.string().nullable()
});

export const conversationNodeElementSchema = z.discriminatedUnion('type', [
  baseConversationNodeElementSchema.extend({
    type: z.literal('TEXT'),
    content: z.string()
  }),
  baseConversationNodeElementSchema.extend({
    type: z.literal('ARTIFACT'),
    artifacts: z.array(conversationNodeArtifactSchema)
  }),
  baseConversationNodeElementSchema.extend({
    type: z.literal('FORM'),
    inputFields: z.array(agentInformationRequestFieldWithValueSchema),
    taskRunId: z.string(),
    wasSubmitted: z.boolean(),
    submittedOn: z.string().optional()
  }),
  baseConversationNodeElementSchema.extend({
    type: z.literal('ACTION'),
    label: z.string().optional(),
    pieceOfInformation: pieceOfInformationDtoSchema.optional(),
    startedOn: z.string(),
    completedOn: z.string().optional(),
    errorMessage: z.string().optional()
  }),
  baseConversationNodeElementSchema.extend({
    type: z.literal('TASK_RUN_STEP_APPROVAL_BUTTON'),
    taskRunStep: z.object({
      id: z.string(),
      wasApproved: z.boolean()
    })
  })
]);

export type ConversationNodeElementType = z.infer<typeof conversationNodeElementTypeSchema>;
export type ConversationNodeEventElementType = z.infer<
  typeof conversationNodeEventElementTypeSchema
>;
export type ConversationNodeElement = z.infer<typeof conversationNodeElementSchema>;

export const upsertBaseConversationNodeElementSchema = z.object({
  id: z.string().optional()
});

export const upsertConversationNodeElementSchema = z.discriminatedUnion('type', [
  upsertBaseConversationNodeElementSchema.extend({
    type: z.literal('TEXT'),
    content: z.string()
  }),
  upsertBaseConversationNodeElementSchema.extend({
    type: z.literal('ARTIFACT'),
    artifacts: z.array(
      z.object({
        pieceOfInformationId: z.string(),
        changeProposalId: z.string().nullish(),
        viewId: z.string().nullish()
      })
    )
  }),
  upsertBaseConversationNodeElementSchema.extend({
    type: z.literal('FORM'),
    inputFields: z.array(agentInformationRequestFieldWithValueSchema),
    taskRunId: z.string(),
    wasSubmitted: z.boolean(),
    submittedOn: z.date().optional()
  }),
  upsertBaseConversationNodeElementSchema.extend({
    type: z.literal('ACTION'),
    label: z.string().optional(),
    pieceOfInformationId: z.string().optional(),
    startedOn: z.date(),
    completedOn: z.date().optional(),
    errorMessage: z.string().optional()
  }),
  upsertBaseConversationNodeElementSchema.extend({
    type: z.literal('TASK_RUN_STEP_APPROVAL_BUTTON'),
    taskRunStep: z.object({
      id: z.string(),
      wasApproved: z.boolean()
    })
  })
]);

export type UpsertConversationNodeElement = z.infer<typeof upsertConversationNodeElementSchema>;
