import { Button, Table } from '..';
import { getEndOfYearRange, getStartOfYearRange, isCurrentMonth } from './utils';

const yearsPerRow = 3;

const getYearGrid = (startYear: number, endYear: number) => {
  const yearGrid = [];
  const totalYears = endYear - startYear + 1;
  let currentYear = startYear;

  for (let i = 0; i < Math.ceil(totalYears / yearsPerRow); i++) {
    const row = [];
    for (let j = 0; j < yearsPerRow && currentYear <= endYear; j++) {
      row.push(currentYear);
      currentYear++;
    }
    yearGrid.push(row);
  }
  return yearGrid;
};

export const YearSelectionView = ({
  centerYear,
  onSelect
}: {
  centerYear: number;
  onSelect: (month: number) => void;
}) => {
  const startYear = getStartOfYearRange(centerYear);
  const endYear = getEndOfYearRange(centerYear);
  const yearGrid = getYearGrid(startYear, endYear);
  return (
    <Table.Root size={'sm'} unstyled h={'full'} w={'full'}>
      <Table.Body>
        {yearGrid.map((yearRow, weekIndex) => (
          <Table.Row key={weekIndex}>
            {yearRow.map((year, dateIndex) => (
              <Table.Cell
                onClick={() => onSelect(year)}
                fontWeight={'medium'}
                key={dateIndex}
                p={0}
                w={'33.333%'}
              >
                <Button size={'xs'} w={'full'} h={'full'} p={0} variant={'ghost'}>
                  {year}
                </Button>
              </Table.Cell>
            ))}
          </Table.Row>
        ))}
      </Table.Body>
    </Table.Root>
  );
};
