'use client';

import { FieldDto, TableDataType, TableDto, TableRowDto } from '@company/common/types';
import React from 'react';

interface ChildTableContextType {
  childTable: TableDto | null | undefined;
  getChildTableValue: (rowId: string, fieldId: string) => TableDataType | undefined;
  getChildTableByParentTableRowId: (parentTableRowId: string) => TableDto | undefined;
  getChildFieldById: (fieldId: string) => FieldDto | undefined;
}

const ChildTableContext = React.createContext<ChildTableContextType | null>(null);

export const ChildTableProvider: React.FC<{
  childTable: TableDto | null | undefined;
  children: React.ReactNode;
}> = ({ childTable, children }) => {
  const rowIdToRowMap = React.useMemo(() => {
    return childTable?.rows.reduce(
      (acc, row) => {
        acc[row.id] = row;
        return acc;
      },
      {} as Record<string, TableRowDto>
    );
  }, [childTable]);

  const getChildTableValue = React.useCallback(
    (rowId: string, fieldId: string) => {
      return rowIdToRowMap?.[rowId]?.[fieldId] as TableDataType;
    },
    [rowIdToRowMap]
  );

  const getChildTableByParentTableRowId = React.useCallback(
    (parentTableRowId: string) => {
      if (!childTable) {
        return undefined;
      }
      return {
        ...childTable,
        rows: childTable.rows.filter(row => row.parentTableRowId === parentTableRowId)
      };
    },
    [childTable]
  );

  const getChildFieldById = React.useCallback(
    (fieldId: string) => {
      if (!childTable) {
        return undefined;
      }
      return childTable.fields.find(field => field.id === fieldId)!;
    },
    [childTable]
  );

  const value = React.useMemo(
    () => ({
      childTable,
      getChildTableValue,
      getChildTableByParentTableRowId,
      getChildFieldById
    }),
    [childTable, getChildTableValue, getChildTableByParentTableRowId, getChildFieldById]
  );

  return <ChildTableContext.Provider value={value}>{children}</ChildTableContext.Provider>;
};

export const useChildTable = (): ChildTableContextType => {
  const context = React.useContext(ChildTableContext);
  if (!context) {
    throw new Error('useChildTable must be used within a ChildTableProvider');
  }
  return context;
};
