'use client';

import { TableDto, ViewDto, ViewState } from '@company/common/types';
import { createZustandContext } from '@stores/create';
import { debounce } from 'lodash';
import { create } from 'zustand';
import { updateTableViewStateAction } from '../actions';
import { IView } from '../types';

const getFieldById = (id: string, table: TableDto) => {
  return table.fields.find(field => field.id === id)!;
};

const convertToTableView = ({ view, table }: { view: ViewDto; table: TableDto }): IView => {
  return {
    ...view,
    state: {
      filters: view.state.filters,
      fields: view.state.fields.map(field => {
        const tableField = getFieldById(field.id, table);
        return {
          ...field,
          referenceId: tableField.referenceId,
          name: tableField.name,
          type: tableField.type,
          isPrimary: tableField.isPrimary,
          isEditable: tableField.isEditable,
          linkedRowConfig: tableField.linkedRowConfig,
          selectConfig: tableField.selectConfig,
          numberConfig: tableField.numberConfig
        };
      })
    }
  };
};

interface InitialState {
  table: TableDto;
  updateTable: (table: TableDto | ((prev: TableDto) => TableDto)) => void;
}

interface ViewStore {
  activeView: IView;
  isViewingChangeProposalDiff: boolean;
  viewChangeProposalDiff: () => void;
  hideChangeProposalDiff: () => void;
  updateActiveViewState: (
    newState: Partial<ViewState>,
    options?: { shouldSaveToDb: boolean }
  ) => void;
}

export const [ViewStoreProvider, useViewStore] = createZustandContext<InitialState, ViewStore>(
  ({ table, updateTable }) =>
    create<ViewStore>((set, get) => {
      const debouncedViewStateSave = debounce((newState: ViewState, viewId: string) => {
        updateTableViewStateAction({ state: newState, viewId });
      }, 1000);

      return {
        isViewingChangeProposalDiff: false,

        activeView: convertToTableView({ view: table.activeView, table }),

        viewChangeProposalDiff: () => {
          set({ isViewingChangeProposalDiff: true });
        },

        hideChangeProposalDiff: () => {
          set({ isViewingChangeProposalDiff: false });
        },

        updateActiveViewState: (
          newState: Partial<ViewState>,
          options?: { shouldSaveToDb?: boolean }
        ) => {
          const activeView = get().activeView;
          const updatedState: ViewState = {
            type: activeView.type,
            ...activeView.state,
            ...newState
          };

          updateTable(table => ({
            ...table,
            activeView: { ...table.activeView, state: updatedState }
          }));

          if (options?.shouldSaveToDb === undefined || options?.shouldSaveToDb) {
            debouncedViewStateSave(updatedState, activeView.id);
          }
        }
      };
    })
);
