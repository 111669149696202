import { Color, TagDto } from '../../types';

export const TAG_DTO_SELECT = {
  id: true,
  groupId: true,
  group: {
    select: {
      type: true
    }
  },
  label: true,
  color: true
};

type Tag = {
  id: string;
  groupId: string;
  group: {
    type: string;
  };
  label: string;
  color: Color;
};

export const convertToTagDto = (tag: Tag): TagDto => {
  return {
    id: tag.id,
    groupId: tag.groupId,
    label: tag.label,
    color: tag.color
  };
};
