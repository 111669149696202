import { usePathname } from 'next/navigation';
import { useEffect, useState } from 'react';

interface Tab {
  id: string;
  hrefSuffix: string;
}

export const usePathnameTab = <T extends Tab>(
  tabs: T[]
): { activeTab: T | null; activeTabIndex: number } => {
  const pathname = usePathname();
  const getTabIndexByPathname = (pathname: string): number => {
    let hasEmptyTab = false;
    for (let i = 0; i < tabs.length; i++) {
      const tab = tabs[i]!;
      if (tab.hrefSuffix === '') {
        hasEmptyTab = true;
        continue;
      }
      if (pathname.includes(tab.hrefSuffix)) {
        return i;
      }
    }
    return hasEmptyTab ? 0 : -1;
  };

  const [activeTabIndex, setActiveTabIndex] = useState<number>(getTabIndexByPathname(pathname));

  useEffect(() => {
    setActiveTabIndex(getTabIndexByPathname(pathname));
  }, [pathname, tabs]);

  const activeTab = tabs[activeTabIndex] || null;

  return { activeTab, activeTabIndex };
};
