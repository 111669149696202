import { z } from 'zod';
import { tableFilterGroupSchema } from './filter';

export const viewFieldStateSchema = z.object({
  id: z.string(),
  width: z.number().optional(),
  isHidden: z.boolean().optional()
});

export const viewTypeSchema = z.enum(['GRID']);

export const viewStateSchema = z.object({
  type: viewTypeSchema,
  fields: z.array(viewFieldStateSchema),
  filters: z.array(tableFilterGroupSchema)
});

export type ViewFieldState = z.infer<typeof viewFieldStateSchema>;
export type ViewType = z.infer<typeof viewTypeSchema>;
export type ViewState<TType extends ViewType = 'GRID'> = z.infer<typeof viewStateSchema> & {
  type: TType;
};

export const viewConfigSchema = z.object({
  canDeleteRows: z.boolean().optional()
});
export type ViewConfig = z.infer<typeof viewConfigSchema>;

export const createTableViewConfigSchema = z.object({
  name: z.string(),
  type: viewTypeSchema,
  state: viewStateSchema,
  config: viewConfigSchema
});
export type CreateTableViewConfig = z.infer<typeof createTableViewConfigSchema>;

export const createTableViewSchema = createTableViewConfigSchema.extend({
  tableId: z.string()
});
export type CreateTableView = z.infer<typeof createTableViewSchema>;
