'use client';

import {
  FieldDto,
  TableDataType,
  TableDto,
  TableRowDto,
  TableRowLinkedRowType
} from '@company/common/types';
import { msg } from '@lingui/core/macro';
import { useLingui } from '@lingui/react';
import {
  FieldToLinkedRowRecommendationData,
  ILinkedRowRecommendationProcessMinimal
} from '@typings/table';
import React from 'react';
import { useFieldStore } from '../stores/field-store';
import { useData } from './data-provider';

interface LinkedRowContextType {
  linkedRowTables: TableDto[] | undefined;
  getLinkedRowTable: ({ tableId }: { tableId: string }) => TableDto | undefined;
  getLinkedRowTableValue: ({
    tableId,
    rowId,
    fieldId
  }: {
    tableId: string;
    rowId: string;
    fieldId: string;
  }) => TableDataType | undefined;
  getLinkedRowTableByParentTableRowId: ({
    tableId,
    parentTableRowId
  }: {
    tableId: string;
    parentTableRowId: string;
  }) => TableDto | undefined;
  getLinkedRowFieldById: ({
    tableId,
    fieldId
  }: {
    tableId: string;
    fieldId: string;
  }) => FieldDto | undefined;
  getLinkedRowRecommendationProcess: ({
    fieldId,
    rowId
  }: {
    fieldId: string;
    rowId: string;
  }) => ILinkedRowRecommendationProcessMinimal | null;
  getLinkedRowTablePrimaryField: ({ tableId }: { tableId: string }) => FieldDto | undefined;
  getLinkedRowWarningMessages: ({ fieldId, rowId }: { fieldId: string; rowId: string }) => string[];
  wasLinkedRowRememberedByAi: ({ fieldId, rowId }: { fieldId: string; rowId: string }) => boolean;
}

const LinkedRowContext = React.createContext<LinkedRowContextType | null>(null);

export const LinkedRowTableProvider: React.FC<{
  linkedRowTables: TableDto[] | undefined;
  fieldToLinkedRowRecommendationData: FieldToLinkedRowRecommendationData | undefined;
  children: React.ReactNode;
}> = ({ linkedRowTables, fieldToLinkedRowRecommendationData, children }) => {
  const { getFieldById } = useFieldStore();
  const { _ } = useLingui();
  const { getValue } = useData();

  const tableMap = React.useMemo(() => {
    if (!linkedRowTables) {
      return {};
    }
    return linkedRowTables.reduce(
      (acc, table) => {
        acc[table.id] = {
          ...table,
          rowMap: table.rows.reduce(
            (acc, row) => {
              acc[row.id] = row;
              return acc;
            },
            {} as Record<string, TableRowDto>
          )
        };
        return acc;
      },
      {} as Record<string, TableDto & { rowMap: Record<string, TableRowDto> }>
    );
  }, [linkedRowTables]);

  const getLinkedRowTableValue = React.useCallback(
    ({ tableId, rowId, fieldId }: { tableId: string; rowId: string; fieldId: string }) => {
      return tableMap[tableId]?.rowMap[rowId]?.[fieldId] as TableDataType;
    },
    [tableMap]
  );

  const fieldIdRowIdToWarningMessagesMap = React.useMemo(() => {
    if (!fieldToLinkedRowRecommendationData) {
      return {};
    }

    return Object.entries(fieldToLinkedRowRecommendationData).reduce(
      (acc, [fieldId, recommendationProcesses]) => {
        const field = getFieldById(fieldId);
        if (!(fieldId in acc)) {
          acc[fieldId] = {};
        }

        recommendationProcesses.forEach(process => {
          const linkedRowValues = getValue(process.rowId, fieldId) as TableRowLinkedRowType;
          const fieldMappingsWithWarning = field.linkedRowConfig?.aiConfig?.fieldMappings.filter(
            mapping =>
              mapping.shouldShowWarningIfNotExactMatch &&
              linkedRowValues.linkedRows.some(
                linkedRow =>
                  getLinkedRowTableValue({
                    tableId: field.linkedRowConfig!.tableId,
                    fieldId: mapping.linkedTableFieldId,
                    rowId: linkedRow.linkedRowId
                  }) !== getValue(process.rowId, mapping.currentTableFieldId)
              )
          );
          const warnings = fieldMappingsWithWarning?.map(mapping =>
            _(
              msg`The column "${getFieldById(mapping.currentTableFieldId)?.name}" are not matching.`
            )
          );
          if (warnings) {
            acc[fieldId]![process.rowId] = warnings;
          }
        });

        return acc;
      },
      {} as Record<string, Record<string, string[]>>
    );
  }, [
    fieldToLinkedRowRecommendationData,
    linkedRowTables,
    getFieldById,
    getValue,
    getLinkedRowTableValue
  ]);

  const getLinkedRowTable = React.useCallback(
    ({ tableId }: { tableId: string }) => {
      return tableMap[tableId];
    },
    [tableMap]
  );

  const getLinkedRowTablePrimaryField = React.useCallback(
    ({ tableId }: { tableId: string }) => {
      return tableMap[tableId]?.fields.find(field => field.isPrimary)!;
    },
    [tableMap]
  );

  const getLinkedRowTableByParentTableRowId = React.useCallback(
    ({ tableId, parentTableRowId }: { tableId: string; parentTableRowId: string }) => {
      const table = tableMap[tableId]!;
      return {
        ...table,
        rows: table.rows.filter(row => row.parentTableRowId === parentTableRowId)
      };
    },
    [tableMap]
  );

  const getLinkedRowFieldById = React.useCallback(
    ({ tableId, fieldId }: { tableId: string; fieldId: string }) => {
      if (!tableMap[tableId]) {
        return undefined;
      }
      return tableMap[tableId]!.fields.find(field => field.id === fieldId)!;
    },
    [tableMap]
  );

  const wasRememberedByAi = React.useCallback(
    ({
      fieldId,
      rowId,
      recommendedRowId
    }: {
      fieldId: string;
      rowId: string;
      recommendedRowId: string;
    }) => {
      if (!fieldToLinkedRowRecommendationData) {
        return false;
      }
      const recommendationProcess = fieldToLinkedRowRecommendationData[fieldId];
      return !!recommendationProcess
        ?.find(process => process.rowId === rowId)
        ?.recommendations.find(
          rec => rec.recommendedRowId === recommendedRowId && rec.isFromAiMemory
        );
    },
    [fieldToLinkedRowRecommendationData]
  );

  const getLinkedRowRecommendationProcess = React.useCallback(
    ({ fieldId, rowId }: { fieldId: string; rowId: string }) => {
      if (!fieldToLinkedRowRecommendationData) {
        return null;
      }
      return (
        fieldToLinkedRowRecommendationData[fieldId]?.find(process => process.rowId === rowId) ??
        null
      );
    },
    [fieldToLinkedRowRecommendationData]
  );

  const getLinkedRowWarningMessages = React.useCallback(
    ({ fieldId, rowId }: { fieldId: string; rowId: string }) =>
      fieldIdRowIdToWarningMessagesMap[fieldId]?.[rowId] ?? [],
    [fieldIdRowIdToWarningMessagesMap]
  );

  const wasLinkedRowRememberedByAi = React.useCallback(
    ({ fieldId, rowId }: { fieldId: string; rowId: string }) => {
      const { linkedRows } = getValue(rowId, fieldId) as TableRowLinkedRowType;
      if (!linkedRows || linkedRows.length === 0) {
        return false;
      }
      return linkedRows.every(linkedRow =>
        wasRememberedByAi({ fieldId, rowId, recommendedRowId: linkedRow.linkedRowId })
      );
    },
    [wasRememberedByAi, getValue]
  );

  const value = React.useMemo(
    () => ({
      linkedRowTables,
      getLinkedRowTable,
      getLinkedRowTableValue,
      getLinkedRowTableByParentTableRowId,
      getLinkedRowFieldById,
      getLinkedRowRecommendationProcess,
      getLinkedRowTablePrimaryField,
      getLinkedRowWarningMessages,
      wasLinkedRowRememberedByAi
    }),
    [
      linkedRowTables,
      getLinkedRowTable,
      getLinkedRowTableValue,
      getLinkedRowTableByParentTableRowId,
      getLinkedRowFieldById,
      getLinkedRowRecommendationProcess,
      getLinkedRowTablePrimaryField,
      getLinkedRowWarningMessages,
      wasLinkedRowRememberedByAi
    ]
  );

  return <LinkedRowContext.Provider value={value}>{children}</LinkedRowContext.Provider>;
};

export const useLinkedRow = (): LinkedRowContextType => {
  const context = React.useContext(LinkedRowContext);
  if (!context) {
    throw new Error('useLinkedRow must be used within a LinkedRowProvider');
  }
  return context;
};
