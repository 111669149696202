import { z } from 'zod';
import {
  CreateTableField,
  createTableFieldSchema,
  fieldLinkedRowConfigSchema,
  FieldType,
  fieldTypeSchema
} from './field';
import { TableRowDto, tableRowFilterSchema, UpsertTableRow, upsertTableRowSchema } from './row';
import { CreateTableViewConfig, createTableViewConfigSchema, ViewState, ViewType } from './view';
import { TableDataType, tableDataTypeSchema } from './data-type';
import { addToKnowledgeLibrarySchema } from '../knowledge';

export const tableTypeSchema = z.enum(['TABLE', 'CHILD_TABLE']);
export type TableType = z.infer<typeof tableTypeSchema>;

export const createTableSchema: z.ZodType<CreateTable> = z.object({
  id: z.string().optional(),
  name: z.string(),
  dataType: tableDataTypeSchema.nullable(),
  projectId: z.string().nullable(),
  fields: z.array(createTableFieldSchema),
  rows: z.array(upsertTableRowSchema),
  views: z.array(createTableViewConfigSchema),
  childTable: z.lazy(() => createTableSchema).nullable(),
  rowTitleTemplate: z.string().nullable(),
  skipAiProcessing: z.boolean().optional()
});

export interface CreateTable {
  id?: string;
  name: string;
  dataType: TableDataType | null;
  projectId: string | null;
  fields: CreateTableField[];
  rows: UpsertTableRow[];
  views: CreateTableViewConfig[];
  childTable: CreateTable | null;
  rowTitleTemplate: string | null;
  skipAiProcessing?: boolean;
}

export interface CloneTableConfig extends CreateTable {
  oldTableId: string;
  fieldMappings: {
    destinationFieldId: string;
    sourceFieldId: string;
  }[];
  childTable: CloneTableConfig | null;
}

export interface ExportTable {
  id: string;
  name: string;
  dataType: TableDataType | null;
  project: {
    id: string;
    name: string;
    description: string | null;
  } | null;
  fields: ExportTableField[];
  rows: TableRowDto[];
  view: ExportTableView;
  childTable: ExportTable | null;
}

export interface ExportTableField {
  id: string;
  referenceId: string;
  name: string;
  type: FieldType;
}

export interface ExportTableView<TType extends ViewType = 'GRID'> {
  name: string;
  type: TType;
  state: ViewState<TType>;
}

export const findTableSchema = z.object({
  tableId: z.string(),
  where: z
    .object({
      row: tableRowFilterSchema
    })
    .optional()
});
export type FindTableArgs = z.infer<typeof findTableSchema>;

export const cloneTableSchema = findTableSchema.extend({
  newTableName: z.string().optional(),
  projectId: z.string().nullable(),
  knowledgeLibrary: addToKnowledgeLibrarySchema,
  doNotCloneRows: z.boolean().optional()
});
export type CloneTableArgs = z.infer<typeof cloneTableSchema>;

export const getRowsSchema = z.object({
  tableId: z.string(),
  fields: z.array(
    z.object({
      id: z.string(),
      type: fieldTypeSchema,
      linkedRowConfig: fieldLinkedRowConfigSchema.nullable()
    })
  ).optional(),
  where: tableRowFilterSchema.optional()
});
export type GetRowsArgs = z.infer<typeof getRowsSchema>;
