export const DAYS_PER_WEEK = 7;
export const YEAR_RANGE = 10;

export const getMonthName = (monthNumber: number) => {
  const date = new Date();
  date.setMonth(monthNumber);

  return date.toLocaleString('en-US', { month: 'long' });
};

export const isToday = (date: Date) => {
  const now = new Date();
  return (
    date.getDate() === now.getDate() &&
    date.getMonth() === now.getMonth() &&
    date.getFullYear() === now.getFullYear()
  );
};

export const isCurrentMonth = (month: number) => {
  const now = new Date();
  return month === now.getMonth();
};

export const isDatePartOfMonth = (date: Date, month: number) => {
  return date.getMonth() === month;
};

export const getStartOfYearRange = (centerYear: number) => {
  return Math.floor(centerYear - YEAR_RANGE / 2) + 1;
};

export const getEndOfYearRange = (centerYear: number) => {
  return Math.floor(centerYear + YEAR_RANGE / 2);
};
