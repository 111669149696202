'use client';

import { Clipboard, Button, toaster } from '@company/ui/components';
import { CheckIcon, Link2Icon } from '@company/ui/icons';
import { msg } from '@lingui/core/macro';
import { useLingui } from '@lingui/react';
import { Trans } from '@lingui/react/macro';

export const ShareButton = () => {
  const { _ } = useLingui();

  const onShareClick = () => {
    toaster.create({
      title: _(msg`Copied to clipboard`),
      description: _(msg`The link has been copied to your clipboard`),
      type: 'success'
    });
  };

  return (
    <Clipboard.Root value={window.location.href}>
      <Clipboard.Trigger asChild onClick={onShareClick}>
        <Button variant={'subtle'} size={'xs'}>
          <Clipboard.Indicator copied={<CheckIcon />}>
            <Link2Icon />
          </Clipboard.Indicator>
          <Trans>Share</Trans>
        </Button>
      </Clipboard.Trigger>
    </Clipboard.Root>
  );
};
